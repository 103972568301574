import React from "react"
import { Input, Row, Col, Form, Button, InputNumber } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { addResult } from "@Redux/actions/result.action"
import { useEffect } from "react"

const AwardForm = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { loadingBtn, results } = useSelector((state) => state.result)
  const onFinish = (data) => {
    dispatch(addResult(data))
  }
  useEffect(() => {
    form.resetFields()
  }, [results])
  return (
    <>
      <Form onFinish={onFinish} layout="vertical" form={form} name="award_form">
        <Row gutter={10}>
          <Col span={3}>
            <label className="form-label">PERCENTAGE</label>
            <Form.Item
              rules={[{ required: true, message: "Invalid Input!" }]}
              name="percentage"
            >
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Input Percentage"
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <label className="form-label">DESCRIPTION</label>
            <Form.Item
              rules={[{ required: true, message: "Invalid Input!" }]}
              name="description"
            >
              <Input.TextArea rows={1} placeholder="Input Description" />
            </Form.Item>
          </Col>
          <Col span={7}>
            <label className="form-label">SOURCE</label>
            <Form.Item
              rules={[{ required: true, message: "Invalid Input!" }]}
              name="source"
            >
              <Input placeholder="Input Sources" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item>
              <Button
                style={{ marginTop: "25px" }}
                type="primary"
                block
                htmlType="submit"
                loading={loadingBtn}
              >
                ADD
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default AwardForm
