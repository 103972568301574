import React from "react"

const ImageComponents = ({ image }) => {
  return (
    <>
      <img src={image} className="image-components" alt={image} />
    </>
  )
}

export default ImageComponents
