import React from "react";
import TechnologyWeOfferForm from "./components/TechnologyWeOfferForm";
import TechnologyWeOfferTable from "./components/TechnologyWeOfferTable";

const TechnologyWeOffer = () => {
  return (
    <div className="containers">
      <h6>
        <span className="heading">Technology We Offer</span>
      </h6>
      <TechnologyWeOfferForm />
      <TechnologyWeOfferTable />
    </div>
  );
};

export default TechnologyWeOffer;
