import { Menu } from "antd";

import { Link, useLocation } from "react-router-dom";
import {
  CompassOutlined,
  CloudServerOutlined,
  TabletOutlined,
  DashboardOutlined,
  BulbOutlined,
  DribbbleSquareOutlined,
  UserOutlined,
  ContactsOutlined,
  MessageOutlined,
  PicCenterOutlined,
  UsergroupAddOutlined,
  FacebookOutlined,
} from "@ant-design/icons";

const { SubMenu } = Menu;

const SideMenu = () => {
  const location = useLocation();

  return (
    <Menu
      //   onClick={this.handleClick}
      style={{ width: "100%" }}
      //   defaultSelectedKeys={['1']}
      //   defaultOpenKeys={['sub1']}
      selectedKeys={[location.pathname]}
      mode="vertical"
    >
      <Menu.Item key="/" icon={<DashboardOutlined />}>
        <Link to="/">DASHBOARD</Link>
      </Menu.Item>
      <SubMenu key={"/abouts"} icon={<CompassOutlined />} title="ABOUT US">
        <Menu.Item key="/about-us">
          <Link to="about-us">ABOUT US CONTENT</Link>
        </Menu.Item>
        <Menu.Item key="/our-team">
          <Link to="our-team">OUR TEAM</Link>
        </Menu.Item>
        <Menu.Item key="/how-we-work">
          <Link to="how-we-work">HOW WE WORK</Link>
        </Menu.Item>
        <Menu.Item key="/our-award">
          <Link to="our-award">OUR AWARD</Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key={"/services-menu"}
        icon={<CloudServerOutlined />}
        title="SERVICES"
      >
        <Menu.Item key="/services">
          <Link to="services">SERVICES LIST</Link>
        </Menu.Item>

        <Menu.Item key="/process-steps">
          <Link to="process-steps">PROCESS STEPS</Link>
        </Menu.Item>
        <Menu.Item key="/result">
          <Link to="result">RESULT LIST</Link>
        </Menu.Item>
      </SubMenu>
      <Menu.Item key="/portfolio" icon={<TabletOutlined />}>
        <Link to="portfolio">PORTFOLIO</Link>
      </Menu.Item>
      <Menu.Item key="/careers" icon={<BulbOutlined />}>
        <Link to="careers">CAREERS</Link>
      </Menu.Item>
      <Menu.Item key="/blogs" icon={<DribbbleSquareOutlined />}>
        <Link to="blogs">BLOGS</Link>
      </Menu.Item>
      <Menu.Item key="/profile" icon={<UserOutlined />}>
        <Link to="profile">PROFILE</Link>
      </Menu.Item>
      <Menu.Item key="/contacts-details" icon={<ContactsOutlined />}>
        <Link to="contacts-details">CONTACT DETAILS</Link>
      </Menu.Item>
      {/* <Menu.Item key="/messages" icon={<MessageOutlined />}>
        <Link to="messages">MESSAGES</Link>
      </Menu.Item> */}
      <Menu.Item key="/testimonials" icon={<UsergroupAddOutlined />}>
        <Link to="testimonials">CLIENT TESTIMONIALS</Link>
      </Menu.Item>
      <Menu.Item key="/seo" icon={<PicCenterOutlined />}>
        <Link to="seo">SEO</Link>
      </Menu.Item>
      <Menu.Item key="/techweoffer" icon={<FacebookOutlined />}>
        <Link to="techweoffer">Technology We Offer</Link>
      </Menu.Item>
      <Menu.Item key="/ourclients" icon={<PicCenterOutlined />}>
        <Link to="ourclients">Our Clients</Link>
      </Menu.Item>
    </Menu>
  );
};

export default SideMenu;
