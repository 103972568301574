import { combineReducers } from "redux";

import contact from "./contact.reducer";
import testimonial from "./testimonial.reducer";
import blogs from "./blog.reducer";
import result from "./result.reducer";
import images from "./image.reducer";
import portfolios from "./portfolio.reducer";
import profile from "./profile.reducer";

import formDrawer from "./drawer.reducer";
import loading from "./loading.reducer";
import auth from "./auth.reducer";
import services from "./service.reducer";
import serviceStep from "./servicestep.reducer";
import awards from "./award.reducer";
import hhws from "./howWeWork.reducer";
import team from "./team.reducer";
import aboutus from "./about.reducer";
import jobs from "./job.reducer";
import messages from "./message.reducer";
import seo from "./seo.reducer";
import techweoffer from "./techweoffer.reducer";
import ourclient from "./client.reducer";
export default combineReducers({
  formDrawer,
  loading,
  auth,
  services,
  serviceStep,
  awards,
  hhws,
  team,
  aboutus,
  contact,
  testimonial,
  blogs,
  jobs,
  messages,
  result,
  images,
  profile,
  portfolios,
  seo,
  techweoffer,
  ourclient,
});
