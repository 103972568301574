import React from "react";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import TeamTable from "./components/TeamTable";
import { openDrawer } from "@Redux/actions/drawer.action";
import TeamForm from "./components/TeamForm";
const Team = () => {
  const dispatch = useDispatch();
  const addTeamFunc = () => {
    dispatch(openDrawer("Add Team Member", <TeamForm />));
  };
  return (
    <div className="containers">
      <h6>
        <span className="heading">Our Team</span>
      </h6>

      <div className="add-btn-justify">
        <Button type="primary" onClick={addTeamFunc} icon={<PlusOutlined />}>
          ADD TEAM MEMBER
        </Button>
      </div>
      <TeamTable />
    </div>
  );
};

export default Team;
