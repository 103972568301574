import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addJob, fetchSingleJob, updateJob } from "@Redux/actions/job.action";
import {
  Form,
  Input,
  Button,
  Upload,
  message,
  InputNumber,
  DatePicker,
} from "antd";

import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import TextEditor from "@Components/TextEditor.component";
import { useSelector } from "react-redux";

const JobForm = ({ update, id }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [fileImg, setFileImg] = useState(null);
  const [img, setImage] = useState(null);
  const [loadingImg, setLoadingImg] = useState(false);
  const { job, loadingBtn } = useSelector((state) => state.jobs);

  useEffect(() => {
    form.resetFields();
    if (update) {
      setImage(`${process.env.REACT_APP_FILE_URL}/images/${job?.image}`);
    }
    return () => {
      setImage(null);
    };
  }, [job, form, update]);
  // -------------------------------

  useEffect(() => {
    if (update && id) {
      dispatch(fetchSingleJob(id));
      // form.setFieldsValue({

      // })
    }
  }, [id, update, dispatch]);

  const beforeUpload = (file, fileList) => {
    const isLt2M = file.size / 1024 / 1024 < 1.5;
    if (!isLt2M) {
      message.error("Image must smaller than 1.5MB!");
      return false;
    }
    setFileImg(fileList);
    setImage(URL.createObjectURL(fileList[0]));
    return true;
  };

  const uploadButton = (
    <div className='upload-button'>
      <div>
        {loadingImg ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    </div>
  );

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "blockquote"],
      ["link", "image"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [{ color: [] }],
      [{ align: [] }],
    ],
  };

  const formats = [
    "header",

    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "align",
    "list",
    "bullet",
    "indent",
  ];

  const initialValue = update
    ? {
        file: job?.image || "",
        title: job?.title || "",
        position: job?.position || "",
        category: job?.category || "",
        written_by: job?.written_by || "",
        description: job?.description || "",
        email_to: job?.email_to || "",
        // end_date: job?.end_date || "",
        work_time: job?.work_time || "",
        salary: job?.salary || "",
      }
    : {};

  const onFinish = (data) => {
    const formData = new FormData();

    for (var key in data) {
      formData.append(key, data[key]);
    }

    if (fileImg) {
      formData.append("image", fileImg[0]);
    }

    if (update) {
      dispatch(updateJob(id, formData));
    } else {
      dispatch(addJob(formData));
    }
  };

  return (
    <>
      <Form
        onFinish={onFinish}
        initialValues={initialValue}
        layout='vertical'
        form={form}
        name='team_form'>
        <label className='form-label'>Image</label>
        <Form.Item
          name='file'
          valuePropName='filelist'
          // getValueFromEvent={normFile}
          // noStyle
          rules={[{ required: true, message: "Invalid Input!" }]}>
          <div>
            <ImgCrop
              style={{ zIndex: "2001" }}
              modalTitle="CROP IMAGE"
              aspect={5 / 4}
            >
              <Upload
                style={{
                  width: "280px",
                  height: "200px",
                  marginBottom: "10px",
                  cursor: "pointer",
                }}
                //   listType="picture-card"
                showUploadList={false}
                className='avatar-uploader'
                // type="drag"
                accept='image/jpg,image/jpeg,image/webp'
                // customRequest={customRequest}
                // onChange={onChange}
                beforeUpload={beforeUpload}>
                {img ? (
                  <img
                    src={img}
                    style={{
                      width: "280px",
                      height: "220px",
                      marginBottom: "20px",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </ImgCrop>
          </div>
        </Form.Item>
        <label className='form-label'>Title</label>
        <Form.Item
          rules={[{ required: true, message: "Invalid Input!" }]}
          name='title'>
          <Input placeholder='Title' />
        </Form.Item>

        <label className='form-label'>Work Time</label>
        <Form.Item
          rules={[{ required: true, message: "Invalid Input!" }]}
          name='work_time'>
          <Input placeholder='Work time' />
        </Form.Item>

        <label className='form-label'>POSITION</label>
        <Form.Item
          rules={[{ required: true, message: "Invalid Input!" }]}
          name='position'>
          <Input placeholder='Position' />
        </Form.Item>
        <label className='form-label'>END DATE</label>
        <Form.Item
          rules={[{ required: true, message: "Invalid Input!" }]}
          name='end_date'>
          <DatePicker placeholder={"End Date"} style={{ width: "100%" }} />
        </Form.Item>
        <label className='form-label'>APPLY EMAIL</label>
        <Form.Item
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            { required: true, message: "Invalid Input!" },
          ]}
          name='email_to'>
          <Input placeholder='Email TO' />
        </Form.Item>
        <label className='form-label'>SALARY</label>
        <Form.Item
          rules={[{ required: true, message: "Invalid Input!" }]}
          name='salary'>
          <Input placeholder='Salary' />
        </Form.Item>
        <label className='form-label'>DESCRIPTION</label>
        <Form.Item
          rules={[
            {
              validator: (_, value) =>
                value && value !== "<p><br></p>"
                  ? Promise.resolve()
                  : Promise.reject(new Error("Invalid Input!")),
            },
          ]}
          name='description'>
          <TextEditor
            modules={modules}
            formats={formats}
            placeholder='Job Description'
          />
        </Form.Item>
        <div
          style={{ display: "flex", width: "100%", justifyContent: "right" }}>
          <Form.Item>
            <Button type='primary' size='large' htmlType='submit'>
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default JobForm;
