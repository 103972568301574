import {
  IMAGE_ADD_SUCCESS,
  IMAGE_FETCH_SUCCESS,
  IMAGE_DELETED_SUCCESS,
} from "./../actions/types"

const initialValue = {
  images: [],
  total: 0,
}

function reducer(state = initialValue, action) {
  switch (action.type) {
    case IMAGE_ADD_SUCCESS:
      return {
        ...state,
        images: [action.payload, ...state.images],
        total: state.total + 1,
      }
    case IMAGE_FETCH_SUCCESS:
      return {
        ...state,
        images: action.payload,
        total: action.total,
      }
    case IMAGE_DELETED_SUCCESS:
      const itemImage = state.images.filter((item) => item.id !== action.id)
      return {
        ...state,
        images: itemImage,
      }
    default:
      return {
        ...state,
      }
  }
}

export default reducer
