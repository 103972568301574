import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, Button, Upload, message } from "antd";
import hljs from "highlight.js";

import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import TextEditor from "@Components/TextEditor.component";
import { useSelector } from "react-redux";
import {
  addBlog,
  fetchSingleBlog,
  updateBlog,
} from "@Redux/actions/blog.action";

const BlogForm = ({ update, id }) => {
  const dispatch = useDispatch();
  hljs.configure({
    languages: [
      "javascript",
      "ruby",
      "cs",
      "python",
      "rust",
      "c",
      "cpp",
      "typescript",
      "go",
      "java",
      "css",
      "curl",
      "dart",
      "django",
      "xml",
      "html",
      "kotlin",
      "less",
      "obj-c",
      "php",
      "scss",
      "rust",
      "shell",
      "swift",
      "vb",
      "yml",
    ],
  });

  const [form] = Form.useForm();
  const [fileImg, setFileImg] = useState(null);
  const [img, setImage] = useState(null);
  const [loadingImg, setLoadingImg] = useState(false);
  const { blog, loadingBtn } = useSelector((state) => state.blogs);

  useEffect(() => {
    form.resetFields();
    if (update) {
      setImage(`${process.env.REACT_APP_FILE_URL}/images/${blog?.image}`);
    }
    return () => {
      setImage(null);
    };
  }, [blog, form, update]);
  // -------------------------------

  useEffect(() => {
    if (update && id) {
      dispatch(fetchSingleBlog(id));
      // form.setFieldsValue({

      // })
    }
  }, [id, update, dispatch]);

  const beforeUpload = (file, fileList) => {
    const isLt2M = file.size / 1024 / 1024 < 1.5;
    if (!isLt2M) {
      message.error("Image must smaller than 1.5MB!");
      return false;
    }
    setFileImg(fileList);
    setImage(URL.createObjectURL(fileList[0]));
    return true;
  };

  const uploadButton = (
    <div className="upload-button">
      <div>
        {loadingImg ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    </div>
  );

  const modules = {
    syntax: {
      highlight: (text) => hljs.highlightAuto(text).value,
    },
    toolbar: [
      [{ font: [] }, { size: [] }],
      ["bold", "italic", "underline"],
      [{ color: [] }, { background: [] }],
      [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [{ align: [] }],
      ["link", "image", "video"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "size",
    "bold",
    "italic",
    "underline",
    "code-block",
    "strike",
    "blockquote",
    "super",
    "sub",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "color",
    "align",
    "list",
    "bullet",
    "indent",
    "font",
  ];

  console.log(blog?.blog);
  const initialValue = update
    ? {
        file: blog?.image || "",
        title: blog?.title || "",
        category: blog?.category || "",
        written_by: blog?.written_by || "",
        blog: blog?.blog || "",
      }
    : {};

  const onFinish = (data) => {
    const formData = new FormData();

    for (var key in data) {
      formData.append(key, data[key]);
    }

    if (fileImg) {
      formData.append("image", fileImg[0]);
    }

    if (update) {
      dispatch(updateBlog(id, formData));
    } else {
      dispatch(addBlog(formData));
    }
  };

  return (
    <>
      <Form
        onFinish={onFinish}
        initialValues={initialValue}
        layout="vertical"
        form={form}
        name="team_form"
      >
        <label className="form-label">Image</label>
        <Form.Item
          name="file"
          valuePropName="filelist"
          // getValueFromEvent={normFile}
          // noStyle
          rules={[{ required: true, message: "Invalid Input!" }]}
        >
          <div>
            <ImgCrop
              style={{ zIndex: "2001" }}
              modalTitle="CROP IMAGE"
              aspect={6 / 4}
            >
              <Upload
                style={{
                  width: "280px",
                  height: "200px",
                  marginBottom: "10px",
                  cursor: "pointer",
                }}
                //   listType="picture-card"
                showUploadList={false}
                className="avatar-uploader"
                // type="drag"
                accept="image/jpg,image/jpeg,image/webp"
                // onChange={onChange}
                beforeUpload={beforeUpload}
              >
                {img ? (
                  <img
                    src={img}
                    style={{
                      width: "280px",
                      height: "220px",
                      marginBottom: "20px",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </ImgCrop>
          </div>
        </Form.Item>
        <label className="form-label">Title</label>
        <Form.Item
          rules={[{ required: true, message: "Invalid Input!" }]}
          name="title"
        >
          <Input placeholder="Title" />
        </Form.Item>

        <label className="form-label">CATEGORY</label>
        <Form.Item
          rules={[{ required: true, message: "Invalid Input!" }]}
          name="category"
        >
          <Input placeholder="Enter Category" />
        </Form.Item>

        <label className="form-label">WRITTEN BY</label>
        <Form.Item
          rules={[{ required: true, message: "Invalid Input!" }]}
          name="written_by"
        >
          <Input placeholder="Written By" />
        </Form.Item>

        <label className="form-label">BLOG</label>
        <Form.Item
          rules={[
            {
              validator: (_, value) =>
                value && value !== "<p><br></p>"
                  ? Promise.resolve()
                  : Promise.reject(new Error("Invalid Input!")),
            },
          ]}
          name="blog"
        >
          <TextEditor
            modules={modules}
            formats={formats}
            placeholder="Write Your Blog."
          />
        </Form.Item>
        <div
          style={{ display: "flex", width: "100%", justifyContent: "right" }}
        >
          <Form.Item>
            <Button
              type="primary"
              loading={loadingBtn}
              size="large"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default BlogForm;
