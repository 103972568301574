import { SearchOutlined } from "@ant-design/icons"
import { Input } from "antd"

export const TableSearchProps = (dataIndex, onSearch) => {
  const data = {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 10 }}>
        <Input.Search
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          size="small"
          allowClear="true"
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }}
          onPressEnter={() => {
            onSearch(selectedKeys, confirm, dataIndex)
            // this.handleSearch(selectedKeys, confirm, dataIndex)
          }}
          style={{ wodth: "100%" }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#f26522" : undefined }} />
    ),
    onFilter: (value, record) => {
      return value
    },

    render: (text) => text,
  }
  return data
}
