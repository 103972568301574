import React, { useState, useEffect } from "react";
import { Button, Col, Popconfirm, Row, Table } from "antd";
import { TableSearchProps } from "hooks/TableSearch";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { fetchBlog, removeBlog } from "@Redux/actions/blog.action";
import { useDispatch, useSelector } from "react-redux";
import Loading from "@Components/Loading.component";
import BlogForm from "./BlogForm.component";
import { openDrawer } from "@Redux/actions/drawer.action";

const BlogTable = () => {
  const dispatch = useDispatch();

  const { blogs, total } = useSelector((state) => state.blogs);
  const [data, setData] = useState([]);

  const updateBlogs = (id) => {
    dispatch(openDrawer("Update Blog", <BlogForm update={true} id={id} />));
  };

  const confirm = (id) => {
    dispatch(removeBlog(id));
  };

  useEffect(() => {
    dispatch(fetchBlog({ pageNumber: 1, pageSize: 10 }));
  }, [dispatch]);

  const columns = [
    {
      title: "SN",
      dataIndex: "sn",
      sorter: (a, b) => a.age - b.age,
      width: "2%",
    },

    {
      title: "Image",
      dataIndex: "image",
      width: "13%",
      render: (image) => (
        <img
          style={{ width: "100px", height: "100px" }}
          src={image}
          alt="image"
        />
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "name",
      filterMode: "menu",
      filterSearch: true,
      width: "40%",
      ...TableSearchProps("name"),
    },
    {
      title: "Category",
      dataIndex: "category",
      width: "10%",
    },
    {
      title: "Written By",
      dataIndex: "written_by",
      width: "15%",
    },

    {
      title: "Action",
      dataIndex: "action",
      width: "20%",
      render: (id) => {
        return (
          <Row justify="center">
            <Col span={10}>
              <Button
                shape="circle"
                onClick={() => {
                  updateBlogs(id);
                }}
                icon={<EditOutlined />}
              />
            </Col>
            <Col span={10}>
              <Popconfirm
                placement="left"
                title={"Are you sure?"}
                onConfirm={() => {
                  confirm(id);
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button shape="circle" danger icon={<DeleteOutlined />} />{" "}
              </Popconfirm>
            </Col>
          </Row>
        );
      },
    },
  ];

  useEffect(() => {
    const mapdata = blogs?.map((item, i) => {
      return {
        sn: i + 1,
        key: item.id,
        image: `${process.env.REACT_APP_FILE_URL}/images/${item.image}`,
        name: item.name,
        title: item.title,
        category: item.category,
        written_by: item.written_by,
        blog: item.blog,
        posted_date: item.posted_date,
        updated_date: item.updated_date,
        action: item.id,
      };
    });
    setData(mapdata);
  }, [blogs]);

  const changePagination = (page, pageSize) => {
    dispatch(fetchBlog({ pageSize: pageSize, pageNumber: page }));
  };

  return (
    <Loading>
      <Table
        bordered
        columns={columns}
        dataSource={data}
        pagination={{
          defaultCurrent: 1,
          total: total,
          // current: pagenumber,
          pageSize: 10,
          onChange: changePagination,
        }}
      />
    </Loading>
  );
};
export default BlogTable;
