import React, { useState } from "react"
import { Input, Row, Col, Form } from "antd"
import { addAward } from "@Redux/actions/award.action"
import { useDispatch, useSelector } from "react-redux"
import Btn from "@Components/Button.component"
const AwardForm = () => {
  const [form] = Form.useForm()
  const [file, setFile] = useState("")
  const dispatch = useDispatch()
  const { addBtnLoading } = useSelector((state) => state.awards)

  const handleFile = (e) => {
    let { files, type } = e.target

    if (type === "file") {
      if (files[0]) {
        setFile(files[0])
      }
    }
  }
  return (
    <>
      <Form
        getFile={(e) => {
          if (Array.isArray(e)) {
            return e
          }
          return e && e.fileList
        }}
        onFinish={(data) => {
          const formData = new FormData()
          formData.append("image", file)
          for (var key in data) {
            if (key !== "image") formData.append(key, data[key])
          }

          dispatch(addAward(formData))
          form.resetFields()
        }}
        layout="vertical"
        form={form}
        name="award_form"
      >
        <Row gutter={16}>
          <Col span={10}>
            <label className="form-label">Award Image</label>
            <Form.Item
              rules={[{ required: true, message: "Invalid Input!" }]}
              name="image"
              valuePropName="filelist"
            >
              <Input
                accept="image/*"
                single
                type="file"
                placeholder="Browser Image"
                onChange={handleFile}
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <label className="form-label">Award Title</label>
            <Form.Item
              rules={[{ required: true, message: "Invalid Input!" }]}
              name="name"
            >
              <Input style={{ width: "100%" }} placeholder="Input Title" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item>
              <Btn addBtnLoading={addBtnLoading} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default AwardForm
