import React from "react"
import { Button } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import { useDispatch } from "react-redux"
import { openDrawer } from "@Redux/actions/drawer.action"
import JobsTable from "./components/JobsTable"
import JobForm from "./components/JobsForm"

const Careers = () => {
  const dispatch = useDispatch()

  const addFunc = () => {
    dispatch(openDrawer("ADD Jobs", <JobForm />))
  }
  return (
    <div className="containers">
      <h6>
        <span className="heading">CAREERS</span>
      </h6>

      <div className="add-btn-justify">
        <Button onClick={addFunc} type="primary" icon={<PlusOutlined />}>
          ADD WORKING ACTION
        </Button>
      </div>
      <JobsTable />
    </div>
  )
}

export default Careers
