import {
  ADD_CONTACT,
  ADD_CONTACT_FAILURE,
  ADD_CONTACT_SUCCESS,
  FETCHED_CONTACT,
  FETCH_CONTACT,
} from "@Redux/actions/types";

const initialState = {
  contact: {},
  loading: false,
  addBtnLoading: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_CONTACT:
      return {
        ...state,
        addBtnLoading: true,
      };
    case ADD_CONTACT_SUCCESS:
      return {
        ...state,
        contact: action.payload,
        addBtnLoading: false,
      };
    case ADD_CONTACT_FAILURE:
      return {
        ...state,
        addBtnLoading: false,
      };
    case FETCH_CONTACT:
      return {
        ...state,
        loading: true,
      };
    case FETCHED_CONTACT:
      return {
        ...state,
        contact: action.payload,
        loading: false,
      };

    default:
      return {
        ...state,
      };
  }
}

export default reducer;
