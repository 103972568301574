import React, { useEffect, useState } from "react";
import Parser from "html-react-parser";
import { Button, Col, Popconfirm, Row, Table } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { fetchServices } from "@Redux/actions/service.action";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import Loading from "@Components/Loading.component";
import ServiceForm from "./ServicesForm";
import { openDrawer } from "@Redux/actions/drawer.action";
import { deleteService } from "@Redux/actions/service.action";

const ServicesTable = () => {
  const dispatch = useDispatch();
  const { loading, services, total } = useSelector((state) => state.services);
  const [data, setData] = useState([]);

  const updateServices = (id) => {
    dispatch(
      openDrawer("Update Service", <ServiceForm update={true} id={id} />),
    );
  };
  const confirm = (id) => {
    dispatch(deleteService(id));
  };

  useEffect(() => {
    dispatch(fetchServices({ pageNumber: 1, pageSize: 10 }));
  }, [dispatch]);

  const columns = [
    {
      title: "SN",
      dataIndex: "sn",
      sorter: (a, b) => a.age - b.age,
      width: "5%",
    },
    {
      title: "IMAGE",
      dataIndex: "image",
      width: "15%",
      render: (image) => (
        <img
          style={{ width: "100px", height: "100px" }}
          src={image}
          alt='image'
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",

      width: "15%",
    },
    {
      title: "Title",
      dataIndex: "title",
      width: "19%",
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (description) => {
        return (
          <div className='line-clamp-4' style={{ width: "100%" }}>
            {Parser(description)}
          </div>
        );
      },
      width: "28%",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "18%",
      render: (id) => {
        return (
          <Row justify='center'>
            <Col span={10}>
              <Button
                shape='circle'
                onClick={() => {
                  updateServices(id);
                }}
                icon={<EditOutlined />}
              />
            </Col>
            <Col span={10}>
              <Popconfirm
                placement='left'
                title={"Are you sure?"}
                onConfirm={() => {
                  confirm(id);
                }}
                okText='Yes'
                cancelText='No'>
                <Button shape='circle' danger icon={<DeleteOutlined />} />{" "}
              </Popconfirm>
            </Col>
          </Row>
        );
      },
    },
  ];

  useEffect(() => {
    const mapdata = services.map((item, i) => {
      return {
        sn: i + 1,
        key: item.id,
        image: `${process.env.REACT_APP_FILE_URL}/images/${item.image}`,
        name: item.name,
        title: item.title,
        description: item.description,
        action: item.id,
      };
    });
    setData(mapdata);
  }, [services]);

  const changePagination = (page, pageSize) => {
    dispatch(fetchServices({ pageSize: pageSize, pageNumber: page }));
  };
  return (
    <Loading>
      <Table
        bordered
        columns={columns}
        dataSource={data}
        pagination={{
          defaultCurrent: 1,
          total: total,
          // current: pagenumber,
          pageSize: 10,
          onChange: changePagination,
        }}
      />
    </Loading>
  );
};
export default ServicesTable;
