import React, { useState } from "react";

import { Select, Input, Button, Row, Col } from "antd";

const { Option } = Select;

const SelectInput = ({ placeholder, onChange, value, options }) => {
  console.log("Is Options", options);
  const [items, setItems] = useState(
    typeof options === "string"
      ? typeof JSON.parse(options) === "string"
        ? JSON.parse(options)?.split(",")
        : JSON.parse(options)
      : options
  );

  console.log("ITEMS,", items);
  const [name, setName] = useState([]);
  const onChangeInput = (e) => {
    const { currentTarget } = e;
    setName(currentTarget.value);
  };
  const onKeyPress = (event) => {
    if (name !== "") {
      setItems((pre) => [...pre, name]);
      setName("");
    }
  };
  return (
    <Select
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      mode="multiple"
      dropdownRender={(menu) => (
        <>
          {menu}
          <Row gutter={16}>
            <Col span={20}>
              <Input
                style={{ margin: "8px 8px" }}
                value={name}
                onChange={onChangeInput}
              />
            </Col>
            <Col span={4}>
              <Button
                block
                style={{ marginTop: "12px" }}
                type="primary"
                onClick={onKeyPress}
              >
                Add
              </Button>
            </Col>
          </Row>
        </>
      )}
    >
      {items?.map((item) => (
        <Option value={item} key={item}>
          {item}
        </Option>
      ))}
    </Select>
  );
};

export default SelectInput;
