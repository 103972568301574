import {
  FETCH_TEAM,
  FETCHED_TEAM,
  DELETE_TEAM,
  ADD_TEAM_SUCCESS,
  ADD_TEAM,
  ADD_TEAM_FAILURE,
  FETCH_SINGLE_SUCCESS,
  UPDATE_TEAM,
  UPDATE_TEAM_SUCCESS,
  UPDATE_TEAM_FAILURE,
} from "./../actions/types"

const initialState = {
  teams: [],
  loading: false,
  formreset: false,
  total: 0,
  team: {},
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TEAM:
      return {
        ...state,
        loading: true,
      }
    case FETCHED_TEAM:
      return {
        ...state,
        teams: action.payload.result,
        total: action.payload.total,
        loading: false,
      }
    case ADD_TEAM:
      return {
        ...state,
      }
    case ADD_TEAM_SUCCESS:
      return {
        ...state,
        teams: [action.payload, ...state.teams],
      }
    case ADD_TEAM_FAILURE:
      return {
        ...state,
      }
    case DELETE_TEAM:
      const itemDeleted = state.teams.filter((item) => item.id !== action.index)

      return {
        ...state,
        teams: itemDeleted,
      }
    case FETCH_SINGLE_SUCCESS:
      return {
        ...state,
        team: action.payload,
      }
    case UPDATE_TEAM:
      return {
        ...state,
      }

    case UPDATE_TEAM_SUCCESS:
      const data = state.teams.map((item) => {
        if (item.id !== action.payload.id) {
          return item
        } else {
          return {
            ...item,
            ...action.payload,
          }
        }
      })
      return {
        ...state,
        teams: data,
        formreset: true,
      }
    case UPDATE_TEAM_FAILURE:
      return {
        ...state,
        formreset: false,
      }

    default:
      return {
        ...state,
      }
  }
}
export default reducer
