import React, { useState, useEffect } from "react"
import { Form, Input, Button, Select, Row, Col, Upload, message } from "antd"
import TextEditor from "@Components/TextEditor.component"
import SelectInput from "@Components/TagsInput.component"
import {
  CodepenOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons"
import ImageUpload from "@Components/ImageUploader.componet"
import InputColor from "@Components/InputColor.components"
import ImgCrop from "antd-img-crop"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { fetchImages } from "@Redux/actions/image.action"
import {
  addPortfolio,
  fetchSinglePortfolio,
  updatePortfolio,
} from "@Redux/actions/portfolio.action"
import { fetchAllServices } from "@Redux/actions/service.action"

const PortfolioForm = ({ id, update }) => {
  const [form] = Form.useForm()
  const [image, setImageOpen] = useState(false)

  const [multiple, setMultiple] = useState(false)
  const [image_list, setImagesList] = useState([])
  const [company_logo, setCompanyLogo] = useState(null)

  const [full_page_image, setFullPageImage] = useState(null)

  const [fileImg, setFileImg] = useState(null)
  const [fileLogo, setFileLogo] = useState(null)
  const [filePageImg, setFileFullPageImg] = useState(null)
  const [img, setImage] = useState(null)

  const { serviceList } = useSelector((state) => state.services)
  const dispatch = useDispatch()

  const { portfolio } = useSelector((state) => state.portfolios)
  useEffect(() => {
    if (update && id) {
      dispatch(fetchSinglePortfolio(id))
    }
  }, [update, id])
  useEffect(() => {
    form.resetFields()
    if (update && portfolio?.image_list) {
      setImagesList(JSON.parse(portfolio?.image_list).split(","))
    }
    if (update && portfolio?.image) {
      setImage(`${process.env.REACT_APP_FILE_URL}/images/${portfolio?.image}`)
    }
    if (update && portfolio?.company_logo) {
      setCompanyLogo(
        `${process.env.REACT_APP_FILE_URL}/images/${portfolio?.company_logo}`
      )
    }
    if (update && portfolio?.wholepage_image) {
      setFullPageImage(
        `${process.env.REACT_APP_FILE_URL}/images/${portfolio?.wholepage_image}`
      )
    }
    return () => {
      form.resetFields()
    }
  }, [portfolio])
  const modules = {
    toolbar: [["bold", "italic", "underline"]],
  }

  const formats = ["bold", "italic", "underline"]
  useEffect(() => {
    dispatch(fetchAllServices())
  }, [dispatch])
  const onSelect = (data) => {
    setImagesList(data)
  }
  const onDeSelect = (name) => {
    const filterData = image_list.filter((item) => item !== name)
    setImagesList(filterData)
  }
  const beforeUpload = (file, fileList) => {
    const isLt2M = file.size / 1024 / 1024 < 1.5
    if (!isLt2M) {
      message.error("Image must smaller than 1.5MB!")
      return false
    }
    setFileImg(fileList)
    setImage(URL.createObjectURL(fileList[0]))
    return true
  }
  const beforeUploadLogo = (file, fileList) => {
    const isLt2M = file.size / 1024 / 1024 < 1.5
    if (!isLt2M) {
      message.error("Image must smaller than 1.5MB!")
      return false
    }
    setFileLogo(fileList)
    setCompanyLogo(URL.createObjectURL(fileList[0]))
    return true
  }
  const beforeUploadFullPage = (file, fileList) => {
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!")
      return false
    }
    setFileFullPageImg(fileList)
    setFullPageImage(URL.createObjectURL(fileList[0]))
    return true
  }

  const onFinish = (data) => {
    const formData = new FormData()

    for (var key in data) {
      formData.append(key, data[key])
    }
    if (image_list) {
      formData.append("image_list", image_list)
    }
    if (fileImg) {
      formData.append("image", fileImg[0])
    }
    if (fileLogo) {
      formData.append("company_logo", fileLogo[0])
    }
    if (filePageImg) {
      formData.append("wholepage_image", filePageImg[0])
    }
    if (update) {
      dispatch(updatePortfolio(id, formData))
    } else {
      dispatch(addPortfolio(formData))
    }
  }

  const initialValue = update
    ? {
        color: portfolio?.color,
        company_logo: portfolio?.company_logo,
        company_name: portfolio?.company_name,
        description: portfolio?.description,
        image: portfolio?.image,
        industry: portfolio?.industry.split(","),
        link: portfolio?.link ? portfolio?.link : "",
        serviceId: portfolio?.serviceId,
        title: portfolio?.title,
        wholepage_image: portfolio?.wholepage_image,
      }
    : {}
  return (
    <>
      <Form
        onFinish={onFinish}
        layout="vertical"
        form={form}
        name="portfolio_form"
        initialValues={initialValue}
      >
        <label className="form-label">PROJECT IMAGE</label>

        <Form.Item
          rules={[{ required: true, message: "Invalid Input!" }]}
          name="image"
          valuePropName="filelist"
        >
          <div>
            <ImgCrop
              style={{ zIndex: "2001" }}
              modalTitle="CROP IMAGE"
              aspect={2 / 2}
            >
              <Upload
                style={{
                  width: "400px",
                  height: "400px",
                  marginBottom: "10px",
                  cursor: "pointer",
                }}
                showUploadList={false}
                accept="image/*"
                beforeUpload={beforeUpload}
              >
                {img ? (
                  <img
                    src={img}
                    style={{
                      width: "200px",
                      height: "200px",
                      marginBottom: "20px",
                      cursor: "pointer",
                    }}
                    alt="portfolio"
                  />
                ) : (
                  <div className="upload-button main-img">
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  </div>
                )}
              </Upload>
            </ImgCrop>
          </div>
        </Form.Item>
        <label className="form-label">PROJECT TITLE</label>
        <Form.Item
          name="title"
          rules={[{ required: true, message: "Invalid Input!" }]}
        >
          <Input placeholder="Enter PROJECT TITLE" />
        </Form.Item>
        <label className="form-label">SERVICES</label>
        <Form.Item
          rules={[{ required: true, message: "Invalid Input!" }]}
          name="serviceId"
        >
          <Select placeholder="Select Services">
            {serviceList.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <label className="form-label">PROJECT INDUSTRY</label>
        <Form.Item
          rules={[{ required: true, message: "Invalid Input!" }]}
          name="industry"
        >
          <SelectInput placeholder="Input Industry" options={[]} />
        </Form.Item>
        <label className="form-label">BACKGROUND COLOR</label>
        <Form.Item
          rules={[{ required: true, message: "Invalid Input!" }]}
          name="color"
        >
          <InputColor />
        </Form.Item>
        <label className="form-label">LINK OF PROJECT</label>
        <Form.Item name="link">
          <Input placeholder="Enter Link" />
        </Form.Item>
        <label className="form-label"> DESCRIPTION</label>
        <Form.Item
          rules={[
            {
              validator: (_, value) =>
                value && value !== "<p><br></p>"
                  ? Promise.resolve()
                  : Promise.reject(new Error("Invalid Input!")),
            },
          ]}
          name="description"
        >
          <TextEditor
            modules={modules}
            formats={formats}
            placeholder="Description"
          />
        </Form.Item>

        <label className="form-label">COMPANY LOGO</label>
        <Form.Item
          rules={[{ required: true, message: "Invalid Input!" }]}
          name="company_logo"
          valuePropName="filelist"
        >
          <div>
            <Upload
              style={{
                width: "200px",
                height: "200px",
                marginBottom: "10px",
                cursor: "pointer",
              }}
              showUploadList={false}
              accept="image/*"
              beforeUpload={beforeUploadLogo}
            >
              {company_logo ? (
                <div className="fit-img bg-logo">
                  <img
                    src={company_logo}
                    style={{
                      width: "100%",
                      height: "auto",
                      marginBottom: "20px",
                      cursor: "pointer",
                    }}
                    alt="portfolio"
                  />
                </div>
              ) : (
                <div className="upload-button main-img">
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                </div>
              )}
            </Upload>
          </div>
        </Form.Item>
        <label className="form-label">COMPANY NAME</label>
        <Form.Item
          name="company_name"
          rules={[{ required: true, message: "Invalid Input!" }]}
        >
          <Input placeholder="Enter Company Name" />
        </Form.Item>
        <label className="form-label">WHOLE PAGE IMAGE</label>
        <Form.Item
          rules={[{ required: true, message: "Invalid Input!" }]}
          name="wholepage_image"
          valuePropName="filelist"
        >
          <div>
            <Upload
              style={{
                width: "200px",
                height: "200px",
                marginBottom: "10px",
                cursor: "pointer",
              }}
              showUploadList={false}
              accept="image/*"
              beforeUpload={beforeUploadFullPage}
            >
              {full_page_image ? (
                <div className="fit-img">
                  <img
                    src={full_page_image}
                    style={{
                      width: "100%",
                      height: "auto",
                      marginBottom: "20px",
                      cursor: "pointer",
                    }}
                    alt="portfolio"
                  />
                </div>
              ) : (
                <div className="upload-button main-img">
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                </div>
              )}
            </Upload>
          </div>
        </Form.Item>
        <label className="form-label">IMAGE LIST</label>

        <Row gutter={16}>
          {image_list.map((item, i) => (
            <Col key={i} span={6}>
              <img
                className="image-list "
                alt="imageList"
                style={{ maxWidth: "150px" }}
                src={`${process.env.REACT_APP_FILE_URL}/images/${item}`}
              />
              <DeleteOutlined
                style={{
                  position: "absolute",
                  background: "red",
                  right: "5%",
                  color: "#ffff",
                  padding: "2px",
                  fontSize: "16px",
                }}
                onClick={() => {
                  onDeSelect(item)
                }}
              />
            </Col>
          ))}

          <Col span={6}>
            <div
              className="upload-button image-list"
              onClick={() => {
                setImageOpen(true)
                setMultiple(true)
              }}
            >
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 6 }}>Upload</div>
              </div>
            </div>
          </Col>
        </Row>

        <div
          style={{ display: "flex", width: "100%", justifyContent: "right" }}
        >
          <Form.Item>
            <Button type="primary" size="large" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
      <ImageUpload
        defaultValue={image_list}
        open={image}
        onSelect={onSelect}
        multiple={multiple}
        onClose={() => {
          setImageOpen(false)
        }}
      />
    </>
  )
}

export default PortfolioForm
