import React from "react";
import OurClientsForm from "./components/OurClientsForm";
import OurClientsTable from "./components/OurClientsTable";

const OurClients = () => {
  return (
    <div className="containers">
      <h6>
        <span className="heading">Our Clients</span>
      </h6>
      <OurClientsForm />
      <OurClientsTable />
    </div>
  );
};

export default OurClients;
