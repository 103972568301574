import React, { useEffect } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import Navbar from "./Navbar"
import Sidebar from "./Sidebar"
import { Spin } from "antd"
const ProtectedLayouts = () => {
  const navigation = useNavigate()
  const { loggedIn } = useSelector((store) => store.auth)

  useEffect(() => {
    if (!loggedIn) {
      navigation("/login")
    }
  }, [loggedIn])

  return (
    <>
      <Navbar />
      <div className="content-layouts">
        <Sidebar />

        <div className="container-layout px-5">
          <Outlet />
        </div>
      </div>
    </>
  )
}

export default ProtectedLayouts
