import React from "react"
import ResultForm from "./components/ResultForm"
import ResultTable from "./components/ResultTable"

const ResultListPage = () => {
  return (
    <div className="containers">
      <h6>
        <span className="heading">Our Result</span>
      </h6>

      <ResultForm />

      <ResultTable />
    </div>
  )
}

export default ResultListPage
