import { PlusOutlined } from "@ant-design/icons"
import { openDrawer } from "@Redux/actions/drawer.action"
import { Button } from "antd"
import React from "react"
import { useDispatch } from "react-redux"
import PortfolioTable from "./Components/PortfolioTable"
import PortfolioForm from "./Components/PortfolioForm"

const PortfolioPage = () => {
  const dispatch = useDispatch()

  const addFunc = () => {
    dispatch(openDrawer("Add To Portfolio", <PortfolioForm />))
  }
  return (
    <div className="containers">
      <h6>
        <span className="heading">OUR PORTFOLIO</span>
      </h6>

      <div className="add-btn-justify">
        <Button type="primary" onClick={addFunc} icon={<PlusOutlined />}>
          ADD TO PORTFOLIO
        </Button>
      </div>
      <PortfolioTable />
    </div>
  )
}

export default PortfolioPage
