import {
  FETCH_HOW_WE_WORK,
  FETCHED_HOW_WE_WORK,
  ADD_HOW_WE_WORK,
  ADD_HOW_WE_WORK_SUCCESS,
  ADD_HOW_WE_WORK_FAILURE,
  DELETE_HOW_WE_WORK,
  UPDATE_HOW_WE_WORK,
  UPDATE_HOW_WE_WORK_SUCCESS,
  UPDATE_HOW_WE_WORK_FAILURE,
  FETCH_SINGLE_SUCCESS,
} from "./../actions/types";

const initialState = {
  hwws: [],
  formreset: false,
  addBtnLoading: false,
  total: 0,
  hww: {},
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_HOW_WE_WORK:
      return {
        ...state,
      };
    case FETCHED_HOW_WE_WORK:
      return {
        ...state,
        hwws: action.payload,
        total: action.total,
      };
    case ADD_HOW_WE_WORK:
      return {
        ...state,
        addBtnLoading: true,
        formreset: false,
      };
    case ADD_HOW_WE_WORK_SUCCESS:
      return {
        ...state,
        addBtnLoading: false,
        formreset: true,
        hwws: [action.payload, ...state.hwws],
      };
    case ADD_HOW_WE_WORK_FAILURE:
      return {
        ...state,
        formreset: false,
        addBtnLoading: false,
      };
    case DELETE_HOW_WE_WORK:
      const deletedHWWS = state.hwws.filter((item) => item.id !== action.index);
      return {
        ...state,
        hwws: deletedHWWS,
      };

    case UPDATE_HOW_WE_WORK:
      return {
        ...state,
      };

    case UPDATE_HOW_WE_WORK_SUCCESS:
      const data = state.hwws.map((item) => {
        if (item.id !== action.payload.id) {
          return item;
        } else {
          return {
            ...item,
            ...action.payload,
          };
        }
      });
      return {
        ...state,

        hwws: data,
        formreset: true,
      };
    case UPDATE_HOW_WE_WORK_FAILURE:
      return {
        ...state,
        formreset: false,
      };
    case FETCH_SINGLE_SUCCESS:
      return {
        ...state,
        hww: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
}
export default reducer;
