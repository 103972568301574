import React, { useState, useEffect } from "react"
import { Modal, Row, Col, Pagination } from "antd"
import CheckboxGroup from "react-checkbox-group"
import { useDispatch, useSelector } from "react-redux"
import ImageComponents from "./Image.component"
import { DeleteOutlined } from "@ant-design/icons"
import {
  addImages,
  deleteImage,
  fetchImages,
} from "@Redux/actions/image.action"

const ImageUpload = ({ open, onSelect, onClose, multiple, defaultValue }) => {
  const [fruits, setFruits] = useState([])

  const [pageNum, setPageNumber] = useState(1)
  const { images, total } = useSelector((state) => state.images)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchImages({ pageNumber: 1, pageSize: 8 }))
  }, [])
  const addNewImage = (e) => {
    const { files } = e.target
    const formData = new FormData()
    formData.append("image", files[0])
    dispatch(addImages(formData))
  }
  useEffect(() => {
    setFruits(defaultValue)

    return () => {
      setFruits([])
    }
  }, [defaultValue])

  const changePage = (page, pageSize) => {
    dispatch(fetchImages({ pageNumber: page, pageSize: pageSize }))
    setPageNumber(page)
  }
  useEffect(() => {
    images.sort((item) => {
      if (defaultValue.includes(item.image)) {
        return -1
      } else {
        return 1
      }
    })
  })
  return (
    <Modal
      title={
        <div className="upload-btn-wrapper">
          <button className="up-btn">Upload A Image</button>
          <input
            onInput={addNewImage}
            type="file"
            name="file"
            accept="image/*"
          />
        </div>
      }
      visible={open}
      onOk={() => {
        onSelect(fruits)
        onClose()
      }}
      onCancel={() => {
        setFruits([])
        onSelect([])
        onClose()
      }}
      width={960}
    >
      <CheckboxGroup
        value={fruits}
        onChange={(value) => {
          if (multiple) {
            setFruits(value)
          } else {
            const single = [value[value.length - 1]]
            setFruits(single)
          }
        }}
      >
        {(Checkbox) => (
          <Row gutter={16}>
            {images.map((item) => {
              return (
                <Col key={item.id} span={6}>
                  <label className="image-checkbox">
                    <ImageComponents
                      image={`${process.env.REACT_APP_FILE_URL}/images/${item.image}`}
                    />

                    <Checkbox value={item.image} />
                  </label>

                  <DeleteOutlined
                    style={{
                      position: "absolute",
                      right: "4%",
                      marginTop: "50px",
                      color: "#ffff",
                      fontSize: "14px",
                      cursor: "pointer",
                      padding: "4px",
                      background: "#e30d0f",
                    }}
                    onClick={() => {
                      const filterdata = fruits.filter(
                        (it) => it !== item.image
                      )
                      setFruits(filterdata)
                      dispatch(deleteImage(item.id))
                    }}
                  />
                </Col>
              )
            })}
          </Row>
        )}
      </CheckboxGroup>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "24px" }}
      >
        <Pagination
          total={total}
          defaultCurrent={1}
          current={pageNum}
          onChange={changePage}
          // hideOnSinglePage={true}
          pageSize={8}
          showSizeChanger={false}
        />
      </div>
    </Modal>
  )
}

export default ImageUpload
