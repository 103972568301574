import {
  LOADING_START,
  LOADING_STOP,
  FETCH_OUR_CLIENT,
  FETCHED_OUR_CLIENT,
  ADD_OUR_CLIENT,
  ADD_OUR_CLIENT_SUCCESS,
  ADD_OUR_CLIENT_FAILURE,
  OUR_CLIENT_DELETED,
} from "./types";
import httpClient from "utils/httpClients";
import notify from "utils/notify";

export const fetchClient = () => (dispatch) => {
  dispatch({ type: LOADING_START });
  dispatch({ type: FETCH_OUR_CLIENT });
  httpClient.GET("/our_client").then((res) => {
    dispatch({ type: FETCHED_OUR_CLIENT, payload: res.data });
    dispatch({ type: LOADING_STOP });
  });
};
export const addClient = (data) => (dispatch) => {
  dispatch({ type: ADD_OUR_CLIENT });
  httpClient
    .POST("/our_client", data)
    .then((res) => {
      dispatch({ type: ADD_OUR_CLIENT_SUCCESS, payload: res.data });
      notify.success("Client added successfully");
    })
    .catch((err) => {
      notify.error(err);
      dispatch({ type: ADD_OUR_CLIENT_FAILURE });
    });
};

export const removeClient = (id) => (dispatch) => {
  httpClient
    .REMOVE("/our_client/" + id)
    .then((res) => {
      dispatch({
        type: OUR_CLIENT_DELETED,
        index: id,
      });
      notify.success("Client deleted successfully");
    })
    .catch((err) => {
      notify.error(err);
    });
};
