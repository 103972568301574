import httpClients from "@Utils/httpClients"
import notify from "@Utils/notify"
import {
  IMAGE_ADD_SUCCESS,
  IMAGE_FETCH_SUCCESS,
  IMAGE_DELETED_SUCCESS,
} from "./types"

export const fetchImages = (option) => (dispatch) => {
  httpClients
    .GET("/image", option)
    .then((out) => {
      dispatch({
        type: IMAGE_FETCH_SUCCESS,
        total: out.data.count,
        payload: out.data.rows,
      })
    })
    .catch((err) => {})
}
export const addImages = (data) => (dispatch) => {
  httpClients
    .POST("/image", data)
    .then((out) => {
      dispatch({ type: IMAGE_ADD_SUCCESS, payload: out.data })

      notify.success("Imag Added.")
    })
    .catch((err) => {
      notify.error(err)
    })
}

export const deleteImage = (id) => (dispatch) => {
  httpClients
    .REMOVE(`/image/${id}`)
    .then((out) => {
      dispatch({ type: IMAGE_DELETED_SUCCESS, id })
      notify.success("Image Deleted.")
    })
    .catch((err) => {
      notify.error(err)
    })
}
