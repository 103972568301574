import React from "react"
import { Input, Button, Dropdown } from "antd"

import Panel from "rc-color-picker/lib/Panel"

export default function InputColor(props) {
  const { onChange, value } = props

  const [internalColor, setInternalColor] = React.useState(value)

  const handleChange = (color) => {
    setInternalColor(color.color)

    if (onChange) {
      onChange(color.color)
    }
  }

  const overlay = (
    <div>
      <Panel
        color={internalColor}
        enableAlpha={false}
        onChange={handleChange}
      />
    </div>
  )

  return (
    <>
      <Input
        value={internalColor || ""}
        size="small"
        placeholder="Select Color"
        onChange={(e) => setInternalColor(e.target.value)}
        suffix={
          <Dropdown trigger={["click"]} overlay={overlay}>
            <Button style={{ background: internalColor }}> </Button>
          </Dropdown>
        }
      />
    </>
  )
}
