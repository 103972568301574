import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_SUCCESS,
  LOGOUT_USER,
} from "./../actions/types";

const initialValue = {
  message: null,
  loggedIn: !!localStorage.getItem("token"),
  loading: false,
  addBtnLoading: false,
};

function reducer(state = initialValue, action) {
  switch (action.type) {
    case CHANGE_PASSWORD:
      return {
        addBtnLoading: true,
      };
    case CHANGE_PASSWORD_SUCCESS:
      localStorage.setItem("token", action.payload);
      return {
        addBtnLoading: false,
      };
    case CHANGE_PASSWORD_FAILURE:
      return {
        addBtnLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}

export default reducer;
