import {
  ADD_BLOG,
  ADD_BLOG_SUCCESS,
  ADD_BLOG_FAILURE,
  FETCH_BLOG,
  FETCHED_BLOG,
  DELETE_BLOG,
  FETCH_BLOG_SUCCESS,
  UPDATE_BLOG_SUCCESS,
} from "./../actions/types";

const initialValue = {
  blogs: [],
  loading: false,
  loadingBtn: false,
  total: 0,
  blog: {},
  blogList: [],
};

function reducer(state = initialValue, action) {
  switch (action.type) {
    case FETCH_BLOG:
      return {
        ...state,
        loadingBtn: true,
      };
    case FETCHED_BLOG:
      return {
        ...state,
        blogs: action.payload.blogs,
        loadingBtn: false,
        total: action.payload.total,
      };

    case FETCH_BLOG_SUCCESS:
      return {
        ...state,
        blog: action.payload,
        loadingBtn: false,
      };

    case ADD_BLOG:
      return {
        ...state,
        loadingBtn: true,
      };
    case ADD_BLOG_SUCCESS:
      return {
        ...state,
        loadingBtn: false,
        blogs: [action.payload, ...state.blogs],
        total: state.total + 1,
      };
    case ADD_BLOG_FAILURE:
      return {
        ...state,
        loadingBtn: false,
      };

    case DELETE_BLOG:
      const itemDeleted = state.blogs.filter(
        (item) => item.id !== action.index
      );
      return {
        ...state,
        blogs: itemDeleted,
      };

    case UPDATE_BLOG_SUCCESS:
      const formatData = state.blogs.map((item) => {
        if (item.id !== action.payload.id) {
          return item;
        } else {
          return {
            ...item,
            ...action.payload,
          };
        }
      });
      return {
        ...state,
        loadingBtn: false,
        blogs: formatData,
      };

    default: {
      return {
        ...state,
      };
    }
  }
}

export default reducer;
