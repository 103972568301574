import React, { useEffect } from "react";
import { Form, Input, Button, Row, Col, Alert } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logInAction } from "@Redux/actions/auth.action";
import { useDispatch } from "react-redux";

const LogInPage = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { loggedIn, loading, message } = useSelector((state) => state.auth);

  useEffect(() => {
    if (loggedIn) {
      navigation("/");
    }
  }, [loggedIn]);

  const onFinish = (data) => {
    dispatch(logInAction(data));
  };
  var well = {
    boxShadow: "4px 8px 4px 2px #00000032",
  };
  return (
    <>
      <div className="login-grid ">
        <Row>
          <Col
            // className="login-input-container"
            style={{
              background: "white",
              padding: "28px",
              border: "1px solid #eeee",
              borderRadius: "10px",
              ...well,
            }}
            xs={{ span: 24, offset: 1 }}
            lg={{ span: 24, offset: 1 }}
          >
            <div className="logo-container">
              <img
                className="logo-image"
                src="/assets/logo.png"
                alt="octopus-infosys"
              />
            </div>

            {message && (
              <div className="login-message">
                <Alert
                  style={{ background: "#ee18183d" }}
                  message={message}
                  type="error"
                  showIcon
                />
              </div>
            )}
            <Form name="normal_login" className="" onFinish={onFinish}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Invalid Input",
                  },
                ]}
              >
                <Input
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  placeholder="Enter Your Email"
                  size="large"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Invalid Input",
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Enter Your Password"
                  size="large"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  loading={loading}
                  block
                  size="large"
                >
                  Log In
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default LogInPage;
