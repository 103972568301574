import React from "react"
import { Drawer } from "antd"
import { LeftOutlined } from "@ant-design/icons"
import { useDispatch, useSelector } from "react-redux"
import { closeDrawer } from "@Redux/actions/drawer.action"

const FormDrawer = () => {
  const dispatch = useDispatch()
  const {
    element: Element,
    open,
    title,
  } = useSelector((store) => store.formDrawer)
  return (
    <>
      <Drawer
        title={
          <h6>
            <span className="heading">{title}</span>
          </h6>
        }
        closeIcon={
          <LeftOutlined style={{ color: "#f5222d", fontSize: "22px" }} />
        }
        width={920}
        bodyStyle={{ paddingBottom: 20 }}
        onClose={() => {
          dispatch(closeDrawer())
        }}
        visible={open}
        // zIndex={2000}
      >
        {Element}
      </Drawer>
    </>
  )
}

export default FormDrawer
