import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  message,
  Row,
  Col,
  InputNumber,
} from "antd";
import {
  LinkedinOutlined,
  GithubOutlined,
  TwitterOutlined,
  FacebookOutlined,
} from "@ant-design/icons";
import { PlusOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import TextEditor from "@Components/TextEditor.component";
import {
  addTeam,
  fetchSingleTeam,
  updateTeam,
} from "@Redux/actions/team.action";
import { useDispatch, useSelector } from "react-redux";
import Btn from "@Components/Button.component";

const TeamForm = ({ update, id }) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [fileImg, setFileImg] = useState([]);
  const [img, setImage] = useState(null);

  const beforeUpload = (file, fileList) => {
    const isLt2M = file.size / 1024 / 1024 < 1.5;
    if (!isLt2M) {
      message.error("Image must smaller than 1.5MB!");
      return false;
    }
    setFileImg(fileList);
    setImage(URL.createObjectURL(fileList[0]));
    return true;
  };
  const { addBtnLoading, formreset, team } = useSelector((state) => state.team);

  useEffect(() => {
    form.resetFields();
    if (update && team) {
      setImage(`${process.env.REACT_APP_FILE_URL}/images/${team?.image}`);
    }
  }, [team, form, update]);
  // -------------------------------

  useEffect(() => {
    if (update && id) {
      dispatch(fetchSingleTeam(id));
    }
  }, [id, update, dispatch]);

  const initialValue = update
    ? {
        sn: team?.sn || "",
        name: team?.name || "",
        category: team?.category || "",
        position: team?.position || "",
        file: team?.image | "",
        description: team?.description || "",
        facebook_link: team?.facebook_link ? team?.facebook_link : "" || "",
        github_link: team?.github_link ? team?.github_link : "" || "",
        linkedIn_link: team?.linkedIn_link ? team?.linkedIn_link : "" || "",
        twitter_link: team?.twitter_link ? team?.twitter_link : "" || "",
      }
    : {};

  const submitForm = (data) => {
    let formData = new FormData();
    if (fileImg.length > 0) {
      formData.append("image", fileImg[0]);
    }

    for (var key in data) {
      formData.append(key, data[key]);
    }
    if (update) {
      dispatch(updateTeam(formData, team?.id));
    } else {
      dispatch(addTeam(formData));
    }
  };
  const uploadButton = (
    <div className="upload-button">
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    </div>
  );

  const modules = {
    toolbar: [["bold", "italic", "underline", "link"]],
  };

  const formats = ["bold", "italic", "underline", "link"];

  return (
    <>
      <Form
        onFinish={(data) => {
          submitForm(data);
        }}
        initialValues={initialValue}
        layout="vertical"
        form={form}
        name="team_form"
      >
        <Form.Item
          name="file"
          valuePropName="filelist"
          style={{ textAlign: "center" }}
          rules={[{ required: true, message: "Invalid Input!" }]}
        >
          <div className="justify-center">
            <ImgCrop
              style={{ zIndex: "2001" }}
              modalTitle="CROP IMAGE"
              aspect={4 / 4}
            >
              <Upload
                style={{
                  width: "200px",
                  height: "200px",
                  marginBottom: "10px",
                  cursor: "pointer",
                }}
                //   listType="picture-card"
                showUploadList={false}
                className="avatar-uploader"
                // type="drag"
                accept="image/jpg,image/jpeg,image/webp"
                beforeUpload={beforeUpload}
              >
                {img ? (
                  <img
                    src={img}
                    style={{
                      width: "200px",
                      height: "220px",
                      marginBottom: "20px",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </ImgCrop>
          </div>
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <label className="form-label">SN</label>
            <Form.Item
              rules={[{ required: true, message: "Invalid Input!" }]}
              name="sn"
            >
              <InputNumber style={{ width: "100%" }} placeholder="Input SN" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <label className="form-label">Full NAME</label>
            <Form.Item
              rules={[{ required: true, message: "Invalid Input!" }]}
              name="name"
            >
              <Input placeholder="Full Name" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <label className="form-label">CATEGORY</label>
            <Form.Item
              rules={[{ required: true, message: "Invalid Input!" }]}
              name="category"
            >
              <Input placeholder="Category" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <label className="form-label">POSITION</label>
            <Form.Item
              rules={[{ required: true, message: "Invalid Input!" }]}
              name="position"
            >
              <Input placeholder="position" />
            </Form.Item>
          </Col>
        </Row>

        <label className="form-label">ABOUT US DESCRIPTION</label>
        <Form.Item
          rules={[
            {
              validator: (_, value) =>
                value && value !== "<p><br></p>"
                  ? Promise.resolve()
                  : Promise.reject(new Error("Invalid Input!")),
            },
          ]}
          name="description"
        >
          <TextEditor
            modules={modules}
            formats={formats}
            placeholder="Describe Team Member"
          />
        </Form.Item>

        <Row gutter={16}>
          <Col span={12}>
            <label className="form-label">LINKEDIN</label>
            <Form.Item name="linkedIn_link">
              <Input
                placeholder="LinkedIn Link"
                prefix={<LinkedinOutlined />}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <label className="form-label">GITHUB</label>
            <Form.Item name="github_link">
              <Input placeholder="Github Link" prefix={<GithubOutlined />} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <label className="form-label">TWITTER</label>
            <Form.Item name="twitter_link">
              <Input placeholder="TWITTER Link" prefix={<TwitterOutlined />} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <label className="form-label">FACEBOOK</label>
            <Form.Item name="facebook_link">
              <Input
                placeholder="Facebook Link"
                prefix={<FacebookOutlined />}
              />
            </Form.Item>
          </Col>
        </Row>
        <div
          style={{ display: "flex", width: "100%", justifyContent: "right" }}
        >
          <Form.Item>
            <Btn addBtnLoading={addBtnLoading} />
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default TeamForm;
