import { LOADING_START, LOADING_STOP } from "./../actions/types"

const initialState = {
  onload: false,
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_STOP:
      return {
        ...state,
        onload: false,
      }
    case LOADING_START:
      return {
        ...state,
        onload: true,
      }
    default:
      return { ...state }
  }
}

export default reducer
