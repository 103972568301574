import SelectInput from "@Components/TagsInput.component";
import { Button, Form, Input } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addContact, fetchContact } from "@Redux/actions/contact.action";
import Btn from "@Components/Button.component";
const ContactPage = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  let { contact, addBtnLoading } = useSelector((state) => state.contact);

  useEffect(() => {
    dispatch(fetchContact());
  }, [dispatch]);

  useEffect(() => {
    form.resetFields();
  }, [contact]);

  const addCon = (data) => {
    dispatch(addContact(data));
  };

  const initialValue = {
    email: contact?.email ? contact?.email : [],
    call: contact?.call ? contact?.call : [],
    location: contact?.location ? contact?.location : [],
  };
  return (
    <div className="containers">
      <h6>
        <span className="heading">OUR CONTACT INFORMATION</span>
      </h6>
      <div className="profile">
        <div className="profile-form-container">
          <Form
            onFinish={(data) => {
              addCon(data);
            }}
            layout="vertical"
            form={form}
            name="contact_form"
            initialValues={initialValue}
          >
            <label className="form-label">EMAIL</label>
            <Form.Item name="email">
              <SelectInput
                options={contact?.email ? contact?.email : []}
                placeholder="Enter Email"
              />
            </Form.Item>
            <label className="form-label">PHONE NUMBER</label>
            <Form.Item name="call">
              <SelectInput
                options={contact?.call ? contact?.call : []}
                placeholder="Enter PhoneNumber"
              />
            </Form.Item>
            <label className="form-label">LOCATION</label>
            <Form.Item name="location">
              <SelectInput
                options={contact?.location ? contact?.location : []}
                placeholder="Enter Location"
              />
            </Form.Item>
            <Form.Item>
              <Btn addBtnLoading={addBtnLoading} />
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
