import React, { useEffect, useState } from "react"

import { Button, Col, Popconfirm, Row, Table } from "antd"
import { TableSearchProps } from "hooks/TableSearch"
import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import Loading from "@Components/Loading.component"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import {
  fetchServiceSte,
  removeProcessList,
} from "@Redux/actions/ServiceStep.action"
import { openDrawer } from "@Redux/actions/drawer.action"
import ServiceProcessStepForm from "./ServiceProcessForm.component"
import Parser from "html-react-parser"

const ServiceProcessTable = () => {
  const dispatch = useDispatch()
  const { processes, total } = useSelector((state) => state.serviceStep)
  const [data, setData] = useState([])
  const updateStepFunc = (id) => {
    dispatch(
      openDrawer(
        "Update Service Process",
        <ServiceProcessStepForm update={true} id={id} />
      )
    )
  }
  const deleteContent = (id) => {
    dispatch(removeProcessList(id))
  }
  const columns = [
    {
      title: "SN",
      dataIndex: "sn",
      sorter: (a, b) => a - b,
      width: "5%",
    },
    {
      title: "Image",
      dataIndex: "image",
      width: "15%",
      render: (item) =>
        item.image && (
          <img
            style={{ width: "auto", height: "100px" }}
            src={`${process.env.REACT_APP_FILE_URL}/images/${item.image}`}
            alt={item.title}
          />
        ),
    },

    {
      title: "Title",
      dataIndex: "title",
      width: "20%",
    },

    {
      title: "Description",
      dataIndex: "description",
      render: (description) => {
        return (
          <div className="line-clamp-4" style={{ width: "100%" }}>
            {Parser(description)}
          </div>
        )
      },
      width: "40%",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "20%",
      render: (id) => {
        return (
          <Row justify="center">
            <Col span={10}>
              <Button
                onClick={() => {
                  updateStepFunc(id)
                }}
                shape="circle"
                icon={<EditOutlined />}
              />
            </Col>
            <Col span={10}>
              <Popconfirm
                placement="left"
                title={"Are you sure?"}
                onConfirm={() => {
                  deleteContent(id)
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button shape="circle" danger icon={<DeleteOutlined />} />
              </Popconfirm>
            </Col>
          </Row>
        )
      },
    },
  ]

  useEffect(() => {
    const mapData = processes.map((item, i) => {
      return {
        sn: item.sn,
        image: item,
        title: item.title,
        description: item.description,
        action: item.id,
      }
    })
    setData(mapData)
  }, [processes])

  useEffect(() => {
    dispatch(fetchServiceSte({ pageNumber: 1, pageSize: 10 }))
  }, [dispatch])
  const changePagination = (page, pageSize) => {
    dispatch(fetchServiceSte({ pageSize: pageSize, pageNumber: page }))
  }
  return (
    <Loading>
      <Table
        bordered
        columns={columns}
        dataSource={data}
        pagination={{
          defaultCurrent: 1,
          total: total,
          // current: pagenumber,
          pageSize: 10,
          onChange: changePagination,
        }}
      />
    </Loading>
  )
}
export default ServiceProcessTable
