import {
  LOADING_START,
  LOADING_STOP,
  FETCH_TECHNOLOGY_WE_OFFER,
  FETCHED_TECHNOLOGY_WE_OFFER,
  ADD_TECHNOLOGY_WE_OFFER,
  ADD_TECHNOLOGY_WE_OFFER_SUCCESS,
  ADD_TECHNOLOGY_WE_OFFER_FAILURE,
  TECHNOLOGY_WE_OFFER_DELETED,
} from "./types";
import httpClient from "utils/httpClients";
import notify from "utils/notify";

export const fetchTechnologies =
  ({ page, limit }) =>
  (dispatch) => {
    dispatch({ type: LOADING_START });
    dispatch({ type: FETCH_TECHNOLOGY_WE_OFFER });
    httpClient.GET("/technology_we_offer", { page, limit }).then((res) => {
      dispatch({ type: FETCHED_TECHNOLOGY_WE_OFFER, payload: res.data });
      dispatch({ type: LOADING_STOP });
    });
  };
export const addTechnologies = (data) => (dispatch) => {
  dispatch({ type: ADD_TECHNOLOGY_WE_OFFER });
  httpClient
    .POST("/technology_we_offer", data)
    .then((res) => {
      dispatch({ type: ADD_TECHNOLOGY_WE_OFFER_SUCCESS, payload: res.data });
      notify.success("Technology we offer added successfully");
    })
    .catch((err) => {
      notify.error(err);
      dispatch({ type: ADD_TECHNOLOGY_WE_OFFER_FAILURE });
    });
};

export const removeTechnologies = (id) => (dispatch) => {
  httpClient
    .REMOVE("/technology_we_offer/" + id)
    .then((res) => {
      dispatch({
        type: TECHNOLOGY_WE_OFFER_DELETED,
        index: id,
      });
      notify.success("Technology we offer deleted successfully");
    })
    .catch((err) => {
      notify.error(err);
    });
};
