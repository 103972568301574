import React, { useEffect } from "react";
import SEOForm from "./Components/SEOForm";
const SEO = () => {
  return (
    <div className="containers">
      <h6>
        <span className="heading">SEO Content</span>
      </h6>
      <div className="form-container">
        <SEOForm />
      </div>
    </div>
  );
};

export default SEO;
