import {
  ACTION_PORTFOLIO_START,
  ACTION_PORTFOLIO_STOP,
  ADD_PORTFOLIO_SUCCESS,
  UPDATE_PORTFOLIO_SUCCESS,
  DELETE_PORTFOLIO_SUCCESS,
  FETCH_PORTFOLIO_SUCCESS,
  FETCH_SINGLE_PORTFOLIO,
} from "./../actions/types"

const initialValue = {
  btnLoading: false,
  portfolios: [],
  single: null,
  total: 0,
  portfolio: null,
}

function reducer(state = initialValue, action) {
  switch (action.type) {
    case FETCH_SINGLE_PORTFOLIO:
      return {
        ...state,
        portfolio: action.payload,
      }
    case ACTION_PORTFOLIO_START:
      return {
        ...state,
        btnLoading: true,
      }
    case ACTION_PORTFOLIO_STOP:
      return {
        ...state,
        btnLoading: false,
      }
    case ADD_PORTFOLIO_SUCCESS:
      return {
        ...state,
        portfolios: [action.payload, ...state.portfolios],
      }
    case FETCH_PORTFOLIO_SUCCESS:
      return {
        ...state,
        total: action.total,
        portfolios: action.payload,
      }
    case UPDATE_PORTFOLIO_SUCCESS:
      const formatData = state.portfolios.map((item) => {
        if (item.id !== action.payload.id) {
          return item
        } else {
          return {
            ...item,
            ...action.payload,
          }
        }
      })
      return {
        portfolios: formatData,
        ...state,
      }

    case DELETE_PORTFOLIO_SUCCESS:
      const itemDeleted = state.blogs.filter((item) => item.id !== action.index)
      return {
        ...state,
        total: state.total - 1,
        portfolios: itemDeleted,
      }
    default:
      return {
        ...state,
      }
  }
}

export default reducer
