import {
  ADD_BLOG,
  ADD_BLOG_SUCCESS,
  ADD_BLOG_FAILURE,
  CLOSE_DRAWER,
  DELETE_BLOG,
  LOADING_START,
  LOADING_STOP,
  FETCH_BLOG,
  FETCH_BLOG_SUCCESS,
  FETCHED_BLOG,
  UPDATE_BLOG_SUCCESS,
  UPDATE_BLOG_FAILURE,
} from "./types";

import httpClients from "utils/httpClients";
import { message } from "antd";
import notify from "utils/notify";

export const fetchBlog =
  ({ pageNumber, pageSize }) =>
  (dispatch) => {
    dispatch({ type: LOADING_START });
    dispatch({ type: FETCH_BLOG });
    httpClients.GET("/blog", { pageNumber, pageSize }).then((res) => {
      dispatch({ type: FETCHED_BLOG, payload: res.data });
      dispatch({ type: LOADING_STOP });
    });
  };

export const addBlog = (data) => (dispatch) => {
  dispatch({ type: ADD_BLOG });
  httpClients
    .POST("/blog", data)
    .then((out) => {
      dispatch({ type: ADD_BLOG_SUCCESS, payload: out.data });
      dispatch({ type: CLOSE_DRAWER });
      message.success("Blog Added Successfully.");
    })
    .catch((error) => {
      dispatch({ type: ADD_BLOG_FAILURE });
      message.error(error.response.data.message);
    });
};

export const removeBlog = (id) => (dispatch) => {
  httpClients
    .REMOVE("/blog/" + id)
    .then((res) => {
      dispatch({
        type: DELETE_BLOG,
        index: id,
      });
      notify.success("Blog deleted successfully");
    })
    .catch((err) => {
      notify.error(err);
    });
};

export const fetchSingleBlog = (id) => (dispatch) => {
  httpClients
    .GET(`/blog/${id}`)
    .then((out) => {
      dispatch({ type: FETCH_BLOG_SUCCESS, payload: out.data });
    })
    .catch((error) => {});
};

export const updateBlog = (id, data) => (dispatch) => {
  dispatch({ type: ADD_BLOG });
  httpClients
    .PUT(`/blog/${id}`, data)
    .then((out) => {
      dispatch({ type: UPDATE_BLOG_SUCCESS, payload: out.data });
      message.success("Blog Updated Successfully.");
      dispatch({ type: CLOSE_DRAWER });
    })
    .catch((error) => {
      dispatch({ type: UPDATE_BLOG_FAILURE });
      message.error(error.response.data.message);
    });
};
