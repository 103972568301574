import React from "react";
import AwardForm from "./components/AwardForm";
import AwardTable from "./components/AwardTable";

const AwardPage = () => {
  return (
    <div className="containers">
      <h6>
        <span className="heading">Our Award</span>
      </h6>
      <AwardForm />
      <AwardTable />
    </div>
  );
};

export default AwardPage;
