import React from "react";
import { Button } from "antd";

const Btn = (props) => {
  let btn = props.addBtnLoading ? (
    <Button
      style={{ marginTop: "25px" }}
      type="primary"
      block
      htmlType="submit"
    >
      ADDING
    </Button>
  ) : (
    <Button
      style={{ marginTop: "25px" }}
      type="primary"
      block
      htmlType="submit"
    >
      ADD
    </Button>
  );
  return btn;
};
export default Btn;
