import { FETCH_MESSAGE, FETCHED_MESSAGE } from "./../actions/types";

const initialValue = {
  messages: [],
  loading: false,
  loadingBtn: false,
  total: 0,
  message: {},
  messageList: [],
};

function reducer(state = initialValue, action) {
  switch (action.type) {
    case FETCH_MESSAGE:
      return {
        ...state,
        loadingBtn: true,
      };
    case FETCHED_MESSAGE:
      return {
        ...state,
        messages: action.payload,
        loadingBtn: false,
      };

    default: {
      return {
        ...state,
      };
    }
  }
}

export default reducer;
