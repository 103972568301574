import React from "react"

const Dashboard = () => {
  return (
    <div className="containers">
      <h6>
        <span className="heading">DASHBOARD</span>
      </h6>
    </div>
  )
}

export default Dashboard
