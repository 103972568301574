import {
  ADD_ABOUT,
  FETCHED_ABOUT,
  ADD_ABOUT_SUCCESS,
  ADD_ABOUT_FAILURE,
  FETCH_ABOUT,
  UPDATE_ABOUT,
  UPDATE_ABOUT_FAILURE,
  UPDATE_ABOUT_SUCCESS,
} from "@Redux/actions/types";

const initialState = {
  aboutus: [],
  loading: false,
  addBtnLoading: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_ABOUT:
      return {
        ...state,
        addBtnLoading: true,
      };
    case ADD_ABOUT_SUCCESS:
      return {
        ...state,
        aboutus: [action.payload, ...state.aboutus],
        addBtnLoading: false,
      };
    case ADD_ABOUT_FAILURE:
      return {
        ...state,
        addBtnLoading: false,
      };

    case FETCH_ABOUT:
      return {
        ...state,
        loading: true,
      };
    case FETCHED_ABOUT:
      return {
        ...state,
        aboutus: action.payload,
        loading: false,
      };

    case UPDATE_ABOUT:
      return {
        ...state,
      };

    case UPDATE_ABOUT_SUCCESS:
      const data = state.aboutus.map((item) => {
        if (item.id !== action.payload.id) {
          return item;
        } else {
          return {
            ...item,
            ...action.payload,
          };
        }
      });
      return {
        ...state,
        aboutus: data,
        loading: false,
      };
    case UPDATE_ABOUT_FAILURE:
      return {
        ...state,
        loading: false,
      };

    default:
      return {
        ...state,
      };
  }
}

export default reducer;
