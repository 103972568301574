import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_SUCCESS,
  LOGOUT_USER,
} from "./types";
import httpClients from "utils/httpClients";
import notify from "utils/notify";

export const changePassword = (data) => (dispatch) => {
  dispatch({ type: CHANGE_PASSWORD });
  httpClients
    .PUT("/users/change-password", data)
    .then((res) => {
      notify.success("Password Changed Successfully");
      dispatch({ type: CHANGE_PASSWORD_SUCCESS, payload: res.data });
      dispatch({ type: LOGOUT_USER });
    })
    .catch((err) => {
      notify.error("Old password did not matched");
      dispatch({ type: CHANGE_PASSWORD_FAILURE });
    });
};
