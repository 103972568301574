import httpClients from "@Utils/httpClients"
import notify from "@Utils/notify"
import {
  ACTION_PORTFOLIO_START,
  ACTION_PORTFOLIO_STOP,
  ADD_PORTFOLIO_SUCCESS,
  FETCH_PORTFOLIO_SUCCESS,
  UPDATE_PORTFOLIO_SUCCESS,
  DELETE_PORTFOLIO_SUCCESS,
  FETCH_SINGLE_PORTFOLIO,
  LOADING_START,
  LOADING_STOP,
  CLOSE_DRAWER,
} from "./types"

export const addPortfolio = (data) => (dispatch) => {
  dispatch({ type: ACTION_PORTFOLIO_START })
  httpClients
    .POST("/portfolio", data)
    .then((out) => {
      dispatch({ type: ADD_PORTFOLIO_SUCCESS, payload: out.data })
      dispatch({ type: ACTION_PORTFOLIO_STOP })
      dispatch({ type: CLOSE_DRAWER })
    })
    .catch((error) => {
      notify.error(error)
      dispatch({ type: ACTION_PORTFOLIO_STOP })
    })
}

export const fetchPortfolio = (option) => (dispatch) => {
  dispatch({ type: LOADING_START })
  httpClients
    .GET("/portfolio", option)
    .then((out) => {
      dispatch({
        type: FETCH_PORTFOLIO_SUCCESS,
        total: out.data.count,
        payload: out.data.rows,
      })
      dispatch({ type: LOADING_STOP })
    })
    .catch((err) => {})
}

export const updatePortfolio = (id, data) => (dispatch) => {
  dispatch({ type: ACTION_PORTFOLIO_START })
  httpClients
    .PUT(`/portfolio/${id}`, data)
    .then((out) => {
      dispatch({ type: UPDATE_PORTFOLIO_SUCCESS, payload: out.data })
      dispatch({ type: CLOSE_DRAWER })
      notify.success("Portfolio Updated.")
    })
    .catch((err) => {
      dispatch({ type: ACTION_PORTFOLIO_STOP })
      notify.error(err)
    })
}

export const deletePortfolios = (id) => (dispatch) => {
  httpClients
    .REMOVE(`/portfolio/${id}`)
    .then((out) => {
      dispatch({ type: DELETE_PORTFOLIO_SUCCESS, id })
      notify.success("Portfolio Removed.")
    })
    .catch((err) => {
      notify.error(err)
    })
}

export const fetchSinglePortfolio = (id) => (dispatch) => {
  httpClients.GET(`/portfolio/${id}`).then((out) => {
    dispatch({ type: FETCH_SINGLE_PORTFOLIO, payload: out.data })
  })
}
