import {
  FETCH_AWARD,
  FETCHED_AWARD,
  DELETE_AWARD,
  ADD_AWARD_SUCCESS,
  ADD_AWARD,
  ADD_AWARD_FAILURE,
} from "./../actions/types"

const initialState = {
  awards: [],
  loading: false,
  formreset: false,
  addBtnLoading: false,
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_AWARD:
      return {
        ...state,
        loading: true,
      }
    case FETCHED_AWARD:
      return {
        ...state,
        awards: action.payload,
        loading: false,
      }
    case ADD_AWARD:
      return {
        ...state,
        addBtnLoading: true,
        formreset: false,
      }
    case ADD_AWARD_SUCCESS:
      return {
        ...state,
        addBtnLoading: false,
        formreset: true,
        awards: [action.payload, ...state.awards],
      }
    case ADD_AWARD_FAILURE:
      return {
        ...state,
        addBtnLoading: false,
      }
    case DELETE_AWARD:
      const itemDeleted = state.awards.filter(
        (item) => item.id !== action.index
      )

      return {
        ...state,
        awards: itemDeleted,
      }

    default:
      return {
        ...state,
      }
  }
}
export default reducer
