import { PlusOutlined } from "@ant-design/icons"
import { Button } from "antd"
import React from "react"
import ServicesTable from "./Components/ServicesTable"
import { useDispatch } from "react-redux"
import ServiceForm from "./Components/ServicesForm"
import { openDrawer } from "@Redux/actions/drawer.action"

const Services = () => {
  const dispatch = useDispatch()

  const addServicesFunc = () => {
    dispatch(openDrawer("Add Service", <ServiceForm />))
  }
  return (
    <div className="containers">
      <h6>
        <span className="heading">OUR SERVICES</span>
      </h6>
      <div className="add-btn-justify">
        <Button
          type="primary"
          onClick={addServicesFunc}
          icon={<PlusOutlined />}
        >
          ADD SERVICE
        </Button>
      </div>
      <ServicesTable />
    </div>
  )
}

export default Services
