import {
  ADD_JOB,
  ADD_JOB_SUCCESS,
  ADD_JOB_FAILURE,
  FETCH_JOB,
  FETCHED_JOB,
  FETCH_JOB_SUCCESS,
  DELETE_JOB,
  UPDATE_JOB,
  UPDATE_JOB_SUCCESS,
  UPDATE_JOB_FAILURE,
} from "./../actions/types";

const initialValue = {
  jobs: [],
  loading: false,
  loadingBtn: false,
  total: 0,
  job: {},
  jobList: [],
};

function reducer(state = initialValue, action) {
  switch (action.type) {
    case FETCH_JOB:
      return {
        ...state,
        loadingBtn: true,
      };
    case FETCHED_JOB:
      return {
        ...state,
        jobs: action.payload.result,
        total: action.payload.total,
        loadingBtn: false,
      };

    case FETCH_JOB_SUCCESS:
      return {
        ...state,
        job: action.payload,
        loadingBtn: false,
      };

    case ADD_JOB:
      return {
        ...state,
        loadingBtn: true,
      };

    case ADD_JOB_SUCCESS:
      return {
        ...state,
        loadingBtn: false,
        jobs: [action.payload, ...state.jobs],
        total: state.total + 1,
      };

    case ADD_JOB_FAILURE:
      return {
        ...state,
        loadingBtn: false,
      };

    case DELETE_JOB:
      const itemDeleted = state.jobs.filter((item) => item.id !== action.index);
      return {
        ...state,
        jobs: itemDeleted,
      };

    case UPDATE_JOB_SUCCESS:
      const formatData = state.jobs.map((item) => {
        if (item.id !== action.payload.id) {
          return item;
        } else {
          return {
            ...item,
            ...action.payload,
          };
        }
      });
      return {
        ...state,
        loadingBtn: false,
        jobs: formatData,
      };

    default: {
      return {
        ...state,
      };
    }
  }
}

export default reducer;
