import React, { useState, useEffect } from "react";
import { Input, Row, Col, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import Btn from "@Components/Button.component";
import { addTechnologies } from "@Redux/actions/techweoffer.action";
const TechnologyWeOfferForm = () => {
  const [form] = Form.useForm();
  const [file, setFile] = useState("");
  const dispatch = useDispatch();
  const { addBtnLoading, formreset } = useSelector(
    (state) => state.techweoffer
  );

  const handleFile = (e) => {
    let { files, type } = e.target;

    if (type === "file") {
      if (files[0]) {
        setFile(files[0]);
      }
    }
  };
  useEffect(() => {
    if (formreset) {
      form.resetFields();
    }
  }, [formreset]);

  const onAddTechWeOffer = (data) => {
    const formData = new FormData();
    formData.append("image", file);
    for (var key in data) {
      if (key !== "image") formData.append(key, data[key]);
    }
    dispatch(addTechnologies(formData));
  };
  return (
    <>
      <Form
        getFile={(e) => {
          if (Array.isArray(e)) {
            return e;
          }
          return e && e.fileList;
        }}
        onFinish={(data) => {
          onAddTechWeOffer(data);
        }}
        layout="vertical"
        form={form}
        name="award_form"
      >
        <Row gutter={16}>
          <Col span={6}>
            <label className="form-label">Technology We Offer Image</label>
            <Form.Item
              rules={[{ required: true, message: "Invalid Input!" }]}
              name="image"
              valuePropName="filelist"
            >
              <Input
                accept="image/*"
                single
                type="file"
                placeholder="Browser Image"
                onChange={handleFile}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <label className="form-label">Technology We Offer Title</label>
            <Form.Item
              rules={[{ required: true, message: "Invalid Input!" }]}
              name="name"
            >
              <Input style={{ width: "100%" }} placeholder="Input Title" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <label className="form-label">Technology We Offer Link</label>
            <Form.Item
              rules={[{ required: true, message: "Invalid Input!" }]}
              name="link"
            >
              <Input style={{ width: "100%" }} placeholder="Input Title" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item>
              <Btn addBtnLoading={addBtnLoading} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default TechnologyWeOfferForm;
