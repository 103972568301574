import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  message,
  InputNumber,
  Select,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";

import TextEditor from "@Components/TextEditor.component";
import { fetchAllServices } from "@Redux/actions/service.action";
import {
  addServiceStep,
  fetchSingleStep,
  updateProcessStep,
} from "@Redux/actions/ServiceStep.action";

const ServiceProcessStepForm = ({ update, id }) => {
  const [form] = Form.useForm();
  const [fileImg, setFileImg] = useState(null);
  const [img, setImage] = useState(null);
  const { serviceList } = useSelector((state) => state.services);
  const { stepProcess, btnLoading } = useSelector((state) => state.serviceStep);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllServices());
  }, [dispatch]);

  function beforeUpload(file, fileList) {
    const isLt2M = file.size / 1024 / 1024 < 1.5;
    if (!isLt2M) {
      message.error("Image must smaller than 1.5MB!");
      return false;
    }
    setFileImg(fileList);
    setImage(URL.createObjectURL(fileList[0]));
    return true;
  }

  const uploadButton = (
    <div className="upload-button">
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    </div>
  );

  useEffect(() => {
    if (update) {
      dispatch(fetchSingleStep(id));
    }
  }, [update, id]);
  useEffect(() => {
    if (update) {
      form.resetFields();
      setImage(
        `${process.env.REACT_APP_FILE_URL}/images/${stepProcess?.image}`
      );
    }
  }, [stepProcess]);

  const initialValue = update
    ? {
        sn: stepProcess?.sn,
        file: stepProcess?.image,
        service_for: stepProcess?.service_for,
        title: stepProcess?.title,
        description: stepProcess?.description,
      }
    : {};

  const modules = {
    toolbar: [["bold", "italic", "underline"]],
  };

  const formats = ["bold", "italic", "underline"];

  const onFinish = (data) => {
    const formData = new FormData();

    for (var key in data) {
      formData.append(key, data[key]);
    }
    if (fileImg) {
      formData.append("gif", fileImg[0]);
    }
    // if (gifImg) {
    //   formData.append("gif", gifImg[0])
    // }
    if (update) {
      dispatch(updateProcessStep(id, formData));
    } else {
      dispatch(addServiceStep(formData));
    }
  };

  return (
    <>
      <Form
        onFinish={onFinish}
        layout="vertical"
        initialValues={initialValue}
        form={form}
        name="team_form"
      >
        <label className="form-label"> GIF FILE</label>
        <Form.Item
          name="file"
          valuePropName="filelist"
          // getValueFromEvent={normFile}
          // noStyle
          rules={[{ required: true, message: "Invalid Input!" }]}
        >
          <Upload
            style={{
              width: "200px",
              height: "200px",
              marginBottom: "10px",
              cursor: "pointer",
            }}
            //   listType="picture-card"
            showUploadList={false}
            className="avatar-uploader"
            // type="drag"
            accept="image/gif,image/svg+xml"
            beforeUpload={beforeUpload}
          >
            {img ? (
              <img
                src={img}
                style={{
                  width: "200px",
                  height: "200px",
                  marginBottom: "20px",
                  cursor: "pointer",
                }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <label className="form-label">SN</label>
        <Form.Item
          rules={[{ required: true, message: "Invalid Input!" }]}
          name="sn"
        >
          <InputNumber style={{ width: "100%" }} placeholder="Input SN" />
        </Form.Item>
        <label className="form-label">SERVICE FOR</label>

        <Form.Item
          rules={[{ required: true, message: "Invalid Input!" }]}
          name="service_for"
        >
          <Select allowClear placeholder="Select Service">
            {serviceList.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <label className="form-label">Title</label>
        <Form.Item
          rules={[{ required: true, message: "Invalid Input!" }]}
          name="title"
        >
          <Input placeholder="Title" />
        </Form.Item>

        <label className="form-label"> DESCRIPTION</label>
        <Form.Item
          rules={[
            {
              validator: (_, value) =>
                value && value !== "<p><br></p>"
                  ? Promise.resolve()
                  : Promise.reject(new Error("Invalid Input!")),
            },
          ]}
          name="description"
        >
          <TextEditor
            modules={modules}
            formats={formats}
            placeholder="Description"
          />
        </Form.Item>

        <div
          style={{ display: "flex", width: "100%", justifyContent: "right" }}
        >
          <Form.Item>
            <Button
              loading={btnLoading}
              type="primary"
              size="large"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default ServiceProcessStepForm;
