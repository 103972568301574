import {
  ADD_JOB,
  ADD_JOB_SUCCESS,
  ADD_JOB_FAILURE,
  FETCH_JOB,
  FETCHED_JOB,
  FETCH_JOB_SUCCESS,
  UPDATE_JOB_SUCCESS,
  UPDATE_JOB_FAILURE,
  DELETE_JOB,
  CLOSE_DRAWER,
  LOADING_START,
  LOADING_STOP,
} from "./types";
import httpClients from "utils/httpClients";
import { message } from "antd";
import notify from "utils/notify";

export const fetchJobs =
  ({ limit, page }) =>
  (dispatch) => {
    dispatch({ type: LOADING_START });
    dispatch({ type: FETCH_JOB });
    httpClients.GET("/job", { limit, page }).then((res) => {
      dispatch({ type: FETCHED_JOB, payload: res.data });
      dispatch({ type: LOADING_STOP });
    });
  };

export const addJob = (data) => (dispatch) => {
  dispatch({ type: ADD_JOB });
  httpClients
    .POST("/job", data)
    .then((out) => {
      dispatch({ type: ADD_JOB_SUCCESS, payload: out.data });
      dispatch({ type: CLOSE_DRAWER });
      message.success("Job Added Successfully.");
    })
    .catch((error) => {
      dispatch({ type: ADD_JOB_FAILURE });
      message.error(error.response.data.message);
    });
};

export const removeJob = (id) => (dispatch) => {
  httpClients
    .REMOVE("/job/" + id)
    .then((res) => {
      dispatch({
        type: DELETE_JOB,
        index: id,
      });
      notify.success("Job deleted successfully");
    })
    .catch((err) => {
      notify.error(err);
    });
};

export const fetchSingleJob = (id) => (dispatch) => {
  httpClients
    .GET(`/job/${id}`)
    .then((out) => {
      dispatch({ type: FETCH_JOB_SUCCESS, payload: out.data });
    })
    .catch((error) => {});
};

export const updateJob = (id, data) => (dispatch) => {
  dispatch({ type: ADD_JOB });
  httpClients
    .PUT(`/job/${id}`, data)
    .then((out) => {
      dispatch({ type: UPDATE_JOB_SUCCESS, payload: out.data });
      message.success("Job Updated Successfully.");
      dispatch({ type: CLOSE_DRAWER });
    })
    .catch((error) => {
      dispatch({ type: UPDATE_JOB_FAILURE });
      message.error(error.response.data.message);
    });
};
