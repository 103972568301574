import {
  FETCH_SERVICE_STEP_SUCCESS,
  ADD_SERVICE_STEP_START,
  ADD_SERVICE_STEP_SUCCESS,
  ADD_SERVICE_STEP_FAIL,
  FETCH_SINGLE_PROCESS,
  UPDATE_PROCESS_STEP,
  UPDATE_PROCESS_STEP_SUCCESS,
  UPDATE_PROCESS_STEP_FAIL,
  DELETED_PROCESS_LIST,
} from "./../actions/types"

const initialState = {
  btnLoading: false,
  processes: [],
  total: 0,
  stepProcess: null,
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SERVICE_STEP_SUCCESS:
      return {
        ...state,
        processes: action.processes,
        total: action.total,
      }
    case ADD_SERVICE_STEP_START:
      return {
        ...state,
        btnLoading: true,
      }
    case ADD_SERVICE_STEP_FAIL:
      return {
        ...state,
        btnLoading: false,
      }
    case ADD_SERVICE_STEP_SUCCESS:
      return {
        ...state,
        btnLoading: false,
        processes: [action.payload, ...state.processes],
        total: state.total + 1,
      }
    case FETCH_SINGLE_PROCESS:
      return {
        ...state,
        stepProcess: action.payload,
      }
    case UPDATE_PROCESS_STEP:
      return {
        ...state,
        btnLoading: true,
      }
    case UPDATE_PROCESS_STEP_SUCCESS:
      const formatData = state.processes.map((item) => {
        if (item.id !== action.payload.id) {
          return item
        } else {
          return {
            ...item,
            ...action.payload,
          }
        }
      })
      return {
        ...state,
        stepProcess: formatData,
        btnLoading: false,
      }
    case UPDATE_PROCESS_STEP_FAIL:
      return {
        ...state,
        btnLoading: false,
      }
    case DELETED_PROCESS_LIST:
      const itemDeleted = state.processes.filter(
        (item) => item.id !== action.id
      )

      return {
        ...state,
        processes: itemDeleted,
        total: state.total - 1,
      }
    default: {
      return {
        ...state,
      }
    }
  }
}

export default reducer
