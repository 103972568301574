import {
  ADD_SERVICE_RESULT_START,
  ADD_SERVICE_RESULT_END,
  ADD_SERVICE_RESULT_SUCCESS,
  FETCH_SERVICE_RESULT,
  DELETE_SERVICE_RESULT,
  LOADING_STOP,
  LOADING_START,
} from "./types"
import httpClient from "utils/httpClients"
import notify from "utils/notify"

export const addResult = (data) => (dispatch) => {
  dispatch({ type: ADD_SERVICE_RESULT_START })
  httpClient
    .POST("/result", data)
    .then((out) => {
      dispatch({ type: ADD_SERVICE_RESULT_SUCCESS, payload: out.data })
      dispatch({ type: ADD_SERVICE_RESULT_END })
      notify.success("Result Added.")
    })
    .catch((err) => {
      notify.error(err)
      dispatch({ type: ADD_SERVICE_RESULT_END })
    })
}

export const fetchResult = (data) => (dispatch) => {
  dispatch({ type: LOADING_START })
  httpClient.GET("/result").then((out) => {
    dispatch({ type: FETCH_SERVICE_RESULT, payload: out.data })
    dispatch({ type: LOADING_STOP })
  })
}

export const removeResult = (id) => (dispatch) => {
  httpClient
    .REMOVE(`/result/${id}`)
    .then((out) => {
      dispatch({ type: DELETE_SERVICE_RESULT, id: id })
      notify.success("Result Removed.")
    })
    .catch((err) => {
      notify.error(err)
    })
}
