import {
  FETCH_HOW_WE_WORK,
  FETCHED_HOW_WE_WORK,
  ADD_HOW_WE_WORK,
  ADD_HOW_WE_WORK_SUCCESS,
  ADD_HOW_WE_WORK_FAILURE,
  DELETE_HOW_WE_WORK,
  UPDATE_HOW_WE_WORK,
  UPDATE_HOW_WE_WORK_FAILURE,
  LOADING_START,
  LOADING_STOP,
  CLOSE_DRAWER,
  UPDATE_HOW_WE_WORK_SUCCESS,
  FETCH_SINGLE_SUCCESS,
} from "./types";
import httpClient from "utils/httpClients";
import notify from "utils/notify";

export const fetchHowWeWork =
  ({ page, limit }) =>
  (dispatch) => {
    dispatch({ type: LOADING_START });
    dispatch({ type: FETCH_HOW_WE_WORK });
    httpClient.GET("/how_we_work", { page, limit }).then((res) => {
      dispatch({
        type: FETCHED_HOW_WE_WORK,
        payload: res.data.result,
        total: res.data.total,
      });

      dispatch({ type: LOADING_STOP });
    });
  };
export const addHowWeWork = (data) => (dispatch) => {
  dispatch({ type: ADD_HOW_WE_WORK });
  httpClient
    .POST("/how_we_work", data)
    .then((res) => {
      notify.success("How we works added successfully");
      dispatch({ type: ADD_HOW_WE_WORK_SUCCESS, payload: res.data });
      dispatch({ type: CLOSE_DRAWER });
    })
    .catch((err) => {
      notify.error(err);
      dispatch({ type: ADD_HOW_WE_WORK_FAILURE });
    });
};

export const removeHowWeWork = (id) => (dispatch) => {
  httpClient
    .REMOVE("/how_we_work/" + id)
    .then((res) => {
      dispatch({
        type: DELETE_HOW_WE_WORK,
        index: id,
      });
      notify.success("HowWeWork deleted successfully");
    })
    .catch((err) => {
      notify.error(err);
    });
};

export const updateHowWeWork = (data) => (dispatch) => {
  dispatch({ type: UPDATE_HOW_WE_WORK });
  httpClient
    .PUT("/how_we_work/" + data.id, data)
    .then((res) => {
      dispatch({ type: UPDATE_HOW_WE_WORK_SUCCESS, payload: res.data });
      notify.success("How We Works Updated Successfully.");
      dispatch({ type: CLOSE_DRAWER });
    })
    .catch((err) => {
      notify.error(err);
      dispatch({ type: UPDATE_HOW_WE_WORK_FAILURE });
    });
};
export const fetchSingleHowWeWork = (id) => (dispatch) => {
  httpClient
    .GET(`/how_we_work/${id}`)
    .then((res) => {
      dispatch({ type: FETCH_SINGLE_SUCCESS, payload: res.data });
    })
    .catch((error) => {});
};
