import {
  ADD_SERVICE_RESULT_START,
  ADD_SERVICE_RESULT_END,
  ADD_SERVICE_RESULT_SUCCESS,
  DELETE_SERVICE_RESULT,
  FETCH_SERVICE_RESULT,
} from "./../actions/types"

const initialState = {
  loadingBtn: false,
  results: [],
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_SERVICE_RESULT_START:
      return {
        ...state,
        loadingBtn: true,
      }
    case FETCH_SERVICE_RESULT:
      return {
        ...state,
        results: action.payload,
      }
    case ADD_SERVICE_RESULT_END:
      return {
        ...state,
        loadingBtn: false,
      }
    case ADD_SERVICE_RESULT_SUCCESS:
      return {
        ...state,
        results: [action.payload, ...state.results],
      }
    case DELETE_SERVICE_RESULT:
      const filterdata = state.results.filter((item) => item.id !== action.id)
      return {
        ...state,
        results: filterdata,
      }
    default: {
      return {
        ...state,
      }
    }
  }
}

export default reducer
