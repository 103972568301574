import {
  ADD_ABOUT_FAILURE,
  ADD_CONTACT,
  ADD_CONTACT_SUCCESS,
  FETCHED_CONTACT,
  FETCH_CONTACT,
} from "./types";

import httpClients from "utils/httpClients";
import notify from "utils/notify";

export const addContact = (data) => (dispatch) => {
  dispatch({ type: ADD_CONTACT });
  httpClients
    .POST("/contact", data)
    .then((res) => {
      notify.success("Contact Info Successfully Added");
      dispatch({ type: ADD_CONTACT_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      notify.error(err);
      dispatch({ type: ADD_ABOUT_FAILURE });
    });
};
export const fetchContact = () => (dispatch) => {
  dispatch({ type: FETCH_CONTACT });
  httpClients.GET("/contact").then((res) => {
    dispatch({ type: FETCHED_CONTACT, payload: res.data });
  });
};
