import React, { useState, useEffect } from "react"
import { Table, Row, Col, Button, Popconfirm } from "antd"
import { DeleteOutlined } from "@ant-design/icons"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import Parser from "html-react-parser"
import { fetchResult, removeResult } from "@Redux/actions/result.action"
import Loading from "@Components/Loading.component"

const AwardTable = () => {
  const dispatch = useDispatch()
  const { results } = useSelector((state) => state.result)
  const [data, setData] = useState([])
  useEffect(() => {
    dispatch(fetchResult())
  }, [dispatch])

  useEffect(() => {
    const mapData = results.map((item, i) => {
      return {
        percentage: item.percentage,
        sn: i + 1,
        description: item.description,
        source: item.source,
        action: item.id,
      }
    })
    setData(mapData)
  }, [results])

  const deleteContent = (id) => {
    dispatch(removeResult(id))
  }

  const columns = [
    {
      title: "SN",
      dataIndex: "sn",

      width: "5%",
    },
    {
      title: "Percentage",
      dataIndex: "percentage",

      width: "10%",
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (description) => {
        return (
          <div className="line-clamp-4" style={{ width: "100%" }}>
            {Parser(description)}
          </div>
        )
      },
      width: "50%",
    },
    {
      title: "Source",
      dataIndex: "source",

      width: "25%",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "10%",
      render: (id) => {
        return (
          <Popconfirm
            placement="left"
            title={"Are you sure?"}
            onConfirm={() => {
              deleteContent(id)
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button shape="circle" danger icon={<DeleteOutlined />} />
          </Popconfirm>
        )
      },
    },
  ]

  return (
    <Loading>
      <Table bordered columns={columns} dataSource={data} pagination={false} />
    </Loading>
  )
}

export default AwardTable
