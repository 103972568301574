import React from "react"
import { Button } from "antd"
import { LogoutOutlined } from "@ant-design/icons"
import { logOutUser } from "@Redux/actions/auth.action"
import { useDispatch } from "react-redux"

const Navbar = () => {
  const dispatch = useDispatch()
  return (
    <div className="nav-bars">
      <div className="logo-area">
        <img
          className="nav-logo-image"
          src="/assets/logo-large.png"
          alt="octopus-infosys"
        />
      </div>

      <div className="info-menu">
        <Button
          type="primary"
          size="small"
          danger
          ghost
          onClick={() => {
            dispatch(logOutUser())
          }}
          icon={<LogoutOutlined />}
        >
          Sign Out
        </Button>
      </div>
    </div>
  )
}

export default Navbar
