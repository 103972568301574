import {
  FETCH_TECHNOLOGY_WE_OFFER,
  FETCHED_TECHNOLOGY_WE_OFFER,
  ADD_TECHNOLOGY_WE_OFFER,
  ADD_TECHNOLOGY_WE_OFFER_SUCCESS,
  ADD_TECHNOLOGY_WE_OFFER_FAILURE,
  TECHNOLOGY_WE_OFFER_DELETED,
} from "./../actions/types";

const initialState = {
  technologies: [],
  formreset: false,
  addBtnLoading: false,
  total: 0,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TECHNOLOGY_WE_OFFER:
      return {
        ...state,
      };
    case FETCHED_TECHNOLOGY_WE_OFFER:
      return {
        ...state,
        technologies: action.payload.result,
        total: action.payload.total,
      };
    case ADD_TECHNOLOGY_WE_OFFER:
      return {
        ...state,
        addBtnLoading: true,
        formreset: false,
      };
    case ADD_TECHNOLOGY_WE_OFFER_SUCCESS:
      return {
        ...state,
        addBtnLoading: false,
        formreset: true,
        technologies: [action.payload, ...state.technologies],
      };
    case ADD_TECHNOLOGY_WE_OFFER_FAILURE:
      return {
        ...state,
        addBtnLoading: false,
      };

    case TECHNOLOGY_WE_OFFER_DELETED:
      const itemDeleted = state.technologies.filter(
        (item) => item.id !== action.index
      );

      return {
        ...state,
        technologies: itemDeleted,
      };

    default:
      return {
        ...state,
      };
  }
}
export default reducer;
