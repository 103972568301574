import React, { useState, useEffect } from "react";
import { Table, Button, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import Loading from "@Components/Loading.component";

import { fetchClient, removeClient } from "@Redux/actions/client.action";

const OurClientsTable = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const { clients } = useSelector((state) => state.ourclient);

  useEffect(() => {
    dispatch(fetchClient({ page: 1, limit: 10 }));
  }, [dispatch]);

  useEffect(() => {
    const mapdata = clients?.map((item, i) => {
      return {
        sn: i + 1,
        key: item.id,
        name: item.name,
        logo: item,
        action: item.id,
      };
    });

    setData(mapdata);
  }, [clients]);

  const deleteClient = (id) => {
    dispatch(removeClient(id));
  };

  // const changePagination = (page, pageSize) => {
  //   dispatch(fetchClient({ page: page, limit: pageSize }));
  // };
  const columns = [
    {
      title: "SN",
      dataIndex: "sn",
      width: "5%",
    },
    {
      title: "Name",
      dataIndex: "name",
      width: "30%",
    },
    {
      title: "Logo",
      dataIndex: "logo",
      render: (data) =>
        data?.logo && (
          <img
            src={`${process.env.REACT_APP_FILE_URL}/images/${data?.logo}`}
            className="award-image"
            alt={data?.logo}
          />
        ),

      width: "30%",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "20%",
      render: (id) => {
        return (
          <Popconfirm
            placement="left"
            title={"Are you sure?"}
            onConfirm={() => {
              deleteClient(id);
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button shape="circle" danger icon={<DeleteOutlined />} />
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <Loading>
      <Table
        bordered
        columns={columns}
        dataSource={data}
        // pagination={{
        //   defaultCurrent: 1,
        //   total: total,

        //   pageSize: 10,
        //   onChange: changePagination,
        // }}
        pagination={false}
      />
    </Loading>
  );
};

export default OurClientsTable;
