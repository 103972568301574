import React, { useEffect, useState } from "react"
import { Button, Col, Row, Table } from "antd"
import { useSelector, useDispatch } from "react-redux"
import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { fetchPortfolio } from "@Redux/actions/portfolio.action"
import Parser from "html-react-parser"
import { openDrawer } from "@Redux/actions/drawer.action"
import PortfolioForm from "./PortfolioForm"
import Loading from "@Components/Loading.component"
const PortfolioTable = () => {
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const { total, portfolios } = useSelector((state) => state.portfolios)
  useEffect(() => {
    dispatch(fetchPortfolio({ pageNumber: 1, pageSize: 10 }))
  }, [])

  const updateFunc = (id) => {
    dispatch(
      openDrawer("Update Portfolio", <PortfolioForm update={true} id={id} />)
    )
  }
  const columns = [
    {
      title: "SN",
      dataIndex: "sn",

      sorter: (a, b) => a.age - b.age,
      width: "5%",
    },
    {
      title: "Image",
      dataIndex: "image",

      width: "13%",
      render: (image) => (
        <img
          style={{ width: "100px", height: "100px" }}
          src={image}
          alt="image"
        />
      ),
    },
    {
      title: "Company Logo",
      dataIndex: "company_logo",

      width: "12%",
      render: (image) => (
        <img
          style={{ width: "100px", height: "auto" }}
          src={image}
          alt="image"
        />
      ),
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      width: "20%",
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (description) => {
        return (
          <div className="line-clamp-4" style={{ width: "100%" }}>
            {Parser(description)}
          </div>
        )
      },
      width: "25%",
    },
    {
      title: "Action",
      dataIndex: "action",

      width: "20%",
      render: (id) => {
        return (
          <Row justify="center">
            <Col span={10}>
              <Button
                shape="circle"
                onClick={() => {
                  updateFunc(id)
                }}
                icon={<EditOutlined />}
              />
            </Col>
            <Col span={10}>
              <Button shape="circle" danger icon={<DeleteOutlined />} />
            </Col>
          </Row>
        )
      },
    },
  ]
  useEffect(() => {
    const mapPortfolio = portfolios.map((item, i) => {
      return {
        sn: i + 1,
        image: `${process.env.REACT_APP_FILE_URL}/images/${item.image}`,
        company_logo: `${process.env.REACT_APP_FILE_URL}/images/${item.company_logo}`,
        company_name: item.company_name,
        description: item.description,
        action: item.id,
      }
    })
    setData(mapPortfolio)
  }, [portfolios])
  const changePagination = (page, pageSize) => {
    dispatch(fetchPortfolio({ pageSize: pageSize, pageNumber: page }))
  }
  return (
    <Loading>
      <Table
        bordered
        columns={columns}
        dataSource={data}
        pagination={{
          defaultCurrent: 1,
          total: total,
          pageSize: 10,
          onChange: changePagination,
        }}
      />
    </Loading>
  )
}
export default PortfolioTable
