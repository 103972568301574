import {
  ADD_SERVICE_START,
  ADD_SERVICE_SUCCESS,
  ADD_SERVICE_FAIL,
  LOADING_START,
  LOADING_STOP,
  FETCH_SERVICES_SUCCESS,
  FETCH_SERVICE_SUCCESS,
  CLOSE_DRAWER,
  UPDATE_SERVICE_SUCCESS,
  DELETE_SERVICE,
  FETCH_ALL_SERVICES,
} from "./types";
import httpClients from "utils/httpClients";
import { message } from "antd";

export const fetchServices =
  ({ pageSize, pageNumber }) =>
  (dispatch) => {
    dispatch({ type: LOADING_START });
    httpClients.GET("/service", { pageNumber, pageSize }).then((out) => {
      dispatch({
        type: FETCH_SERVICES_SUCCESS,
        total: out.data.count,
        payload: out.data.rows,
      });
      dispatch({
        type: LOADING_STOP,
      });
    });
  };

export const fetchSingleService = (id) => (dispatch) => {
  httpClients
    .GET(`/service/${id}`)
    .then((out) => {
      dispatch({ type: FETCH_SERVICE_SUCCESS, payload: out.data });
    })
    .catch((error) => {});
};

export const addService = (data) => (dispatch) => {
  dispatch({ type: ADD_SERVICE_START });
  httpClients
    .POST("/service", data)
    .then((out) => {
      dispatch({ type: ADD_SERVICE_SUCCESS, payload: out.data });
      dispatch({ type: CLOSE_DRAWER });
      message.success("Service Added Successfully.");
    })
    .catch((error) => {
      dispatch({ type: ADD_SERVICE_FAIL });
      message.error(error.response.data.message);
    });
};

export const updateService = (id, data) => (dispatch) => {
  dispatch({ type: ADD_SERVICE_START });
  httpClients
    .PUT(`/service/${id}`, data)
    .then((out) => {
      dispatch({ type: UPDATE_SERVICE_SUCCESS, payload: out.data });
      message.success("Service Updated Successfully.");
      dispatch({ type: CLOSE_DRAWER });
    })
    .catch((error) => {
      dispatch({ type: ADD_SERVICE_FAIL });
      message.error(error.response.data.message);
    });
};

export const deleteService = (id) => (dispatch) => {
  httpClients
    .REMOVE(`/service/${id}`)
    .then((out) => {
      dispatch({ type: DELETE_SERVICE, id: id });
      message.success("Service Deleted Successfully.");
    })
    .catch((error) => {
      message.error(error.response.data.message);
    });
};

export const fetchAllServices = () => (dispatch) => {
  httpClients.GET("/service/list").then((out) => {
    dispatch({ type: FETCH_ALL_SERVICES, payload: out.data });
  });
};
