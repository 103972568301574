import React from "react"
import { Button } from "antd"
import { useDispatch } from "react-redux"
import { PlusOutlined } from "@ant-design/icons"
import { openDrawer } from "@Redux/actions/drawer.action"
import TestimonialsTable from "./components/TestimonialsTable"
import TestimonialForm from "./components/TestimonialsForm"

const Testimonials = () => {
  const dispatch = useDispatch()

  const addTeamFunc = () => {
    dispatch(openDrawer("ADD TESTIMONIAL", <TestimonialForm />))
  }
  return (
    <div className="containers">
      <h6>
        <span className="heading">Client Testimonials</span>
      </h6>

      <div className="add-btn-justify">
        <Button type="primary" onClick={addTeamFunc} icon={<PlusOutlined />}>
          ADD TESTIMONIALS
        </Button>
      </div>
      <TestimonialsTable />
    </div>
  )
}

export default Testimonials
