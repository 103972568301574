import {
  FETCH_SEO,
  FETCHED_SEO,
  ADD_SEO,
  ADD_SEO_SUCCESS,
  ADD_SEO_FAILURE,
  UPDATE_SEO,
  UPDATE_SEO_SUCCESS,
  UPDATE_SEO_FAILURE,
} from "./types";
import httpClients from "utils/httpClients";
import notify from "utils/notify";

export const fetchSEO = () => (dispatch) => {
  dispatch({ type: FETCH_SEO });
  httpClients.GET("/seo").then((res) => {
    dispatch({ type: FETCHED_SEO, payload: res.data });
  });
};

export const addSEO = (data) => (dispatch) => {
  dispatch({ type: ADD_SEO });
  httpClients
    .POST("/seo", data)
    .then((res) => {
      notify.success("SEO Successfully Added");
      dispatch({ type: ADD_SEO_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      notify.error(err);
      dispatch({ type: ADD_SEO_FAILURE });
    });
};

export const updateSEO = (data, id) => (dispatch) => {
  dispatch({ type: UPDATE_SEO });
  httpClients
    .POST("/seo", data)
    .then((res) => {
      notify.success("SEO Successfully updated");
      dispatch({ type: UPDATE_SEO_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      notify.error(err);
      dispatch({ type: UPDATE_SEO_FAILURE });
    });
};
