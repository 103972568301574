import React, { useState, useEffect } from "react";
import { Button, Col, Popconfirm, Row, Table, Switch } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { TableSearchProps } from "hooks/TableSearch";
import { useDispatch, useSelector } from "react-redux";
import Loading from "@Components/Loading.component";
import { fetchJobs, removeJob } from "@Redux/actions/job.action";
import { openDrawer } from "@Redux/actions/drawer.action";
import Parser from "html-react-parser";
import JobForm from "./JobsForm";

const JobsTable = () => {
  const dispatch = useDispatch();
  const { jobs, total } = useSelector((state) => state.jobs);
  const [data, setData] = useState([]);

  const updateJob = (id) => {
    dispatch(openDrawer("Update Job", <JobForm update={true} id={id} />));
  };

  const confirm = (id) => {
    dispatch(removeJob(id));
  };

  const changeStatus = (id, status) => {
    // dispatch(statusChange(id, active));
  };

  useEffect(() => {
    dispatch(fetchJobs({ pageNumber: 1, pageSize: 10 }));
  }, [dispatch]);

  const columns = [
    {
      title: "SN",
      dataIndex: "sn",
      sorter: (a, b) => a.age - b.age,
      width: "5%",
    },

    {
      title: "Image",
      dataIndex: "image",
      width: "10%",
      render: (image) => (
        <img
          style={{ width: "100px", height: "100px" }}
          src={image}
          alt="image"
        />
      ),
    },

    {
      title: "Title",
      dataIndex: "title",
      key: "name",
      width: "10%",
    },
    {
      title: "Decription",
      dataIndex: "description",
      render: (description) => {
        return (
          <div className="line-clamp-4" style={{ width: "100%" }}>
            {Parser(description)}
          </div>
        );
      },
      width: "15%",
    },
    {
      title: "Salary",
      dataIndex: "salary",
      width: "10%",
    },
    {
      title: "Date End",
      dataIndex: "end_date",
      width: "10%",
    },
    {
      title: "Position",
      dataIndex: "position",
      width: "10%",
    },
    {
      title: "Work Time",
      dataIndex: "work_time",
      width: "5%",
    },
    {
      title: "Active",
      dataIndex: "status",
      render: (status, id) => {
        return (
          <Switch
            checkedChildren="ACTIVE"
            unCheckedChildren="NOT ACTIVE"
            defaultChecked={status}
            onClick={() => {
              changeStatus(id.key, status);
            }}
          />
        );
      },
      width: "10%",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "15%",
      render: (id) => {
        return (
          <Row justify="center">
            <Col span={10}>
              <Button
                shape="circle"
                onClick={() => {
                  updateJob(id);
                }}
                icon={<EditOutlined />}
              />
            </Col>
            <Col span={10}>
              <Popconfirm
                placement="left"
                title={"Are you sure?"}
                onConfirm={() => {
                  confirm(id);
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button shape="circle" danger icon={<DeleteOutlined />} />{" "}
              </Popconfirm>
            </Col>
          </Row>
        );
      },
    },
  ];

  useEffect(() => {
    const mapdata = jobs.map((item, i) => {
      return {
        sn: i + 1,
        key: item.id,
        image: `${process.env.REACT_APP_FILE_URL}/images/${item.image}`,
        name: item.name,
        title: item.title,
        description: item.description,
        salary: item.salary,
        end_date: item.end_date,
        position: item.position,
        work_time: item.work_time,
        status: item.active,
        action: item.id,
      };
    });
    setData(mapdata);
  }, [jobs]);

  const changePagination = (page, pageSize) => {
    dispatch(fetchJobs({ limit: pageSize, page: page }));
  };

  return (
    <Loading>
      <Table
        bordered
        columns={columns}
        dataSource={data}
        pagination={{
          defaultCurrent: 1,
          total: total,
          // current: pagenumber,
          pageSize: 10,
          onChange: changePagination,
        }}
      />
    </Loading>
  );
};

export default JobsTable;
