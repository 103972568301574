import {
  ADD_ABOUT,
  ADD_ABOUT_FAILURE,
  ADD_ABOUT_SUCCESS,
  FETCHED_ABOUT,
  FETCH_ABOUT,
  UPDATE_ABOUT_SUCCESS,
  UPDATE_ABOUT,
  UPDATE_ABOUT_FAILURE,
} from "./types";
import httpClients from "utils/httpClients";
import notify from "utils/notify";

export const fetchAbout = () => (dispatch) => {
  dispatch({ type: FETCH_ABOUT });
  httpClients.GET("/about").then((res) => {
    dispatch({ type: FETCHED_ABOUT, payload: res.data });
  });
};

export const addAbout = (data) => (dispatch) => {
  dispatch({ type: ADD_ABOUT });
  httpClients
    .POST("/about", data)
    .then((res) => {
      notify.success("About Us Successfully Added");
      dispatch({ type: ADD_ABOUT_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      notify.error(err);
      dispatch({ type: ADD_ABOUT_FAILURE });
    });
};

export const updateAbout = (data, id) => (dispatch) => {
  dispatch({ type: UPDATE_ABOUT });
  httpClients
    .PUT("/about/" + id, data)
    .then((res) => {
      notify.success("About Us Successfully updated");
      dispatch({ type: UPDATE_ABOUT_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      notify.error(err);
      dispatch({ type: UPDATE_ABOUT_FAILURE });
    });
};
