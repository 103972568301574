import { LOGOUT_USER, LOGIN_SUCCESS, LOGIN_FAIL, LOGIN_START } from "./types"
import httpClients from "utils/httpClients"

export const logInAction = (data) => (dispatch) => {
  dispatch({ type: LOGIN_START })
  httpClients
    .POST("/v1/auth", data)
    .then((out) => {
      dispatch({ type: LOGIN_SUCCESS, payload: out.data.token })
    })
    .catch((error) => {
      dispatch({ type: LOGIN_FAIL, message: error.response.data.message })
    })
}

export const logOutUser = () => (dispatch) => {
  dispatch({ type: LOGOUT_USER })
}
