export const OPEN_DRAWER = "OPEN_DRAWER";
export const CLOSE_DRAWER = "CLOSE_DRAWER";
export const LOADING_START = "LOADING_START";
export const LOADING_STOP = "LOADING_STOP";
export const SERVICE_PROCESS_START = "SERVICE_PROCESS_START";
export const SERVICE_PROCESS_SUCCESS = "SERVICE_PROCESS_SUCCESS";
export const SERVICE_PROCESS_FAIL = "SERVICE_PROCESS_FAIL";
export const FETCH_ALL_SERVICES = "FETCH_ALL_SERVICES";
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_START = "LOGIN_START";
export const ADD_SERVICE_START = "ADD_SERVICE_START";
export const ADD_SERVICE_SUCCESS = "ADD_SERvICE_SUCCESS";
export const ADD_SERVICE_FAIL = "ADD_SERVICE_FAIL";
export const FETCH_SERVICES_START = "FETCH_SERVICES_START";
export const FETCH_SERVICES_SUCCESS = "FETCH_SERVICES_SUCCESS";
export const FETCH_SERVICE_SUCCESS = "FETCH_SERVICE_SUCCESS";
export const UPDATE_SERVICE_SUCCESS = "UPDATE_SERVICE_SUCCESS";
export const DELETE_SERVICE = "DELETE_SERVICE";
export const FETCH_SERVICE_STEP_SUCCESS = "FETCH_SERVICE_STEP_SUCCESS";
export const ADD_SERVICE_STEP_START = "ADD_SERVICE_STEP_START";
export const ADD_SERVICE_STEP_SUCCESS = "ADD_SERVICE_STEP_SUCCESS";
export const ADD_SERVICE_STEP_FAIL = "ADD_SERVICE_STEP_FAIL";

//How We Work

export const FETCH_HOW_WE_WORK = "FETCH_HOW_WE_WORK";
export const FETCHED_HOW_WE_WORK = "FETCHED_HOW_WE_WORK";
export const ADD_HOW_WE_WORK = "ADD_HOW_WE_WORK";
export const ADD_HOW_WE_WORK_SUCCESS = "ADD_HOW_WE_WORK_SUCCESS";
export const ADD_HOW_WE_WORK_FAILURE = "ADD_HOW_WE_WORK_FAILURE";
export const DELETE_HOW_WE_WORK = "DELETE_HOW_WE_WORK";
export const UPDATE_HOW_WE_WORK = "UPDATE_HOW_WE_WORK";
export const UPDATE_HOW_WE_WORK_SUCCESS = "UPDATE_HOW_WE_WORK_SUCCESS";
export const UPDATE_HOW_WE_WORK_FAILURE = "UPDATE_HOW_WE_WORK_FAILURE";
export const FETCH_SINGLE_SUCCESS = "FETCH_SINGLE_SUCCESS";

//Award
export const FETCH_AWARD = "FETCH_AWARD";
export const FETCHED_AWARD = "FETCHED_AWARD";
export const DELETE_AWARD = "DELETE_AWARD";
export const ADD_AWARD = "ADD_AWARD";
export const ADD_AWARD_SUCCESS = "ADD_AWARD_SUCCESS";
export const ADD_AWARD_FAILURE = "ADD_AWARD_FAILURE";

//Blog
export const FETCH_BLOG = "FETCH_BLOG";
export const FETCH_BLOG_SUCCESS = "FETCH_BLOG_SUCCESS";
export const FETCHED_BLOG = "FETCHED_BLOG";
export const DELETE_BLOG = "DELETE_BLOG";
export const ADD_BLOG = "ADD_BLOG";
export const ADD_BLOG_SUCCESS = "ADD_BLOG_SUCCESS";
export const ADD_BLOG_FAILURE = "ADD_BLOG_FAILURE";
export const UPDATE_BLOG = "UPDATE_BLOG";
export const UPDATE_BLOG_SUCCESS = "UPDATE_BLOG_SUCCESS";
export const UPDATE_BLOG_FAILURE = "UPDATE_BLOG_FAILURE";

//Team

export const FETCH_TEAM = "FETCH_TEAM";
export const FETCHED_TEAM = "FETCHED_TEAM";
export const DELETE_TEAM = "DELETE_TEAM";
export const ADD_TEAM = "ADD_TEAM";
export const ADD_TEAM_SUCCESS = "ADD_TEAM_SUCCESS";
export const ADD_TEAM_FAILURE = "ADD_TEAM_FAILURE";
export const UPDATE_TEAM = "UPDATE_TEAM";
export const UPDATE_TEAM_SUCCESS = "UPDATE_TEAM_SUCCESS";
export const UPDATE_TEAM_FAILURE = "UPDATE_TEAM_FAILURE";

//About

export const ADD_ABOUT = "ADD_ABOUT";
export const ADD_ABOUT_SUCCESS = "ADD_ABOUT_SUCCESS";
export const ADD_ABOUT_FAILURE = "ADD_ABOUT_FAILURE";
export const FETCH_ABOUT = "FETCH_ABOUT";
export const FETCHED_ABOUT = "FETCHED_ABOUT";
export const UPDATE_ABOUT = "UPDATE_ABOUT";
export const UPDATE_ABOUT_SUCCESS = "UPDATE_ABOUT_SUCCESS";
export const UPDATE_ABOUT_FAILURE = "UPDATE_ABOUT_FAILURE";

// Jobs | careers
export const FETCH_JOB = "FETCH_JOB";
export const FETCHED_JOB = "FETCHED_JOB";
export const FETCH_JOB_SUCCESS = "FETCH_JOB_SUCCESS";
export const ADD_JOB = "ADD_JOB";
export const ADD_JOB_SUCCESS = "ADD_JOB_SUCCESS";
export const ADD_JOB_FAILURE = "ADD_JOB_FAILURE";
export const DELETE_JOB = "DELETE_JOB";
export const UPDATE_JOB = "UPDATE_JOB";
export const UPDATE_JOB_SUCCESS = "UPDATE_JOB_SUCCESS";
export const UPDATE_JOB_FAILURE = "UPDATE_JOB_FAILURE";
export const CHANGE_JOB_STATUS = "CHANGE_JOB_STATUS";

// Clients Messages
export const FETCH_MESSAGE = "FETCH_MESSAGE";
export const FETCHED_MESSAGE = "FETCHED_MESSAGE";

//Contact
export const ADD_CONTACT = "ADD_CONTACT";
export const ADD_CONTACT_SUCCESS = "ADD_CONTACT_SUCCESS";
export const ADD_CONTACT_FAILURE = "ADD_CONTACT_FAILURE";
export const FETCH_CONTACT = "FETCH_CONTACT";
export const FETCHED_CONTACT = "FETCHED_CONTACT";

// Process List
export const FETCH_SINGLE_PROCESS = "FETCH_SINGLE_PROCESS";
export const UPDATE_PROCESS_STEP = "UPDATE_PROCESS_STEP";
export const UPDATE_PROCESS_STEP_SUCCESS = "UPDATE_PROCESS_STEP_SUCCESS";
export const UPDATE_PROCESS_STEP_FAIL = "UPDATE_PROCESS_STEP_FAIL";
export const DELETED_PROCESS_LIST = "DELETED_PROCESS_LIST";

//Result
export const ADD_SERVICE_RESULT_START = "ADD_SERVICE_RESULT_START";
export const ADD_SERVICE_RESULT_END = "ADD_SERVICE_RESULT_END";
export const FETCH_SERVICE_RESULT = "FETCH_SERVICE_RESULT";
export const DELETE_SERVICE_RESULT = "DELETE_SERVICE_RESULT";
export const ADD_SERVICE_RESULT_SUCCESS = "ADD_SERVICE_SUCCESS";

// Protfolio

export const ACTION_PORTFOLIO_START = "ADD_PORTFOLIO_START";
export const ACTION_PORTFOLIO_STOP = "ACTION_PORTFOLIO_STOP";
export const FETCH_PORTFOLIO_SUCCESS = "FETCH_PORTFOLIO_SUCCESS";
export const ADD_PORTFOLIO_SUCCESS = "ADD_PORTFOLIO_SUCCESS";
export const UPDATE_PORTFOLIO_SUCCESS = "UPDATE_PORTFOLIO_SUCCESS";
export const DELETE_PORTFOLIO_SUCCESS = "DELETE_PORTFOLIO_SUCCESS";
export const FETCH_SINGLE_PORTFOLIO = "FETCH_SINGLE_PORTFOLIO";

// Image

export const IMAGE_ADD_SUCCESS = "IMAGE_ADD_SUCCESS";
export const IMAGE_FETCH_SUCCESS = "IMAGE_FETCH_SUCCESS";
export const IMAGE_DELETED_SUCCESS = "IMAGE_DELETED_SUCCESS";

//Testimonials
export const ADD_TESTIMONIAL = "ADD_TESTIMONIAL";
export const ADD_TESTIMONIAL_FAILURE = "ADD_TESTIMONIAL_FAILURE";
export const ADD_TESTIMONIAL_SUCCESS = "ADD_TESTIMONIAL_SUCCESS";
export const DELETE_TESTIMONIAL = "DELETE_TESTIMONIAL";
export const FETCHED_TESTIMONIAL = "FETCHED_TESTIMONIAL";
export const FETCH_TESTIMONIAL = "FETCH_TESTIMONIAL";
export const UPDATE_TESTIMONIAL = "UPDATE_TESTIMONIAL";
export const UPDATE_TESTIMONIAL_SUCCESS = "UPDATE_TESTIMONIAL_SUCCESS";
export const UPDATE_TESTIMONIAL_FAILURE = "UPDATE_TESTIMONIAL_FAILURE";

//Profile
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

//SEO
export const ADD_SEO = "ADD_SEO";
export const ADD_SEO_SUCCESS = "ADD_SEO_SUCCESS";
export const ADD_SEO_FAILURE = "ADD_SEO_FAILURE";
export const FETCH_SEO = "FETCH_SEO";
export const FETCHED_SEO = "FETCHED_SEO";
export const UPDATE_SEO = "UPDATE_SEO";
export const UPDATE_SEO_SUCCESS = "UPDATE_SEO_SUCCESS";
export const UPDATE_SEO_FAILURE = "UPDATE_SEO_FAILURE";

//Technology we offer
export const ADD_TECHNOLOGY_WE_OFFER = "ADD_TECHNOLOGY_WE_OFFER";
export const ADD_TECHNOLOGY_WE_OFFER_SUCCESS =
  "ADD_TECHNOLOGY_WE_OFFER_SUCCESS";
export const ADD_TECHNOLOGY_WE_OFFER_FAILURE =
  "ADD_TECHNOLOGY_WE_OFFER_FAILURE";
export const FETCH_TECHNOLOGY_WE_OFFER = "ADD_TECHNOLOGY_WE_OFFER";
export const FETCHED_TECHNOLOGY_WE_OFFER = "FETCHED_TECHNOLOGY_WE_OFFER";
export const TECHNOLOGY_WE_OFFER_DELETED = "TECHNOLOGY_WE_OFFER_DELETED";

//Our Client
export const ADD_OUR_CLIENT = "ADD_OUR_CLIENT";
export const ADD_OUR_CLIENT_SUCCESS = "ADD_OUR_CLIENT_SUCCESS";
export const ADD_OUR_CLIENT_FAILURE = "ADD_OUR_CLIENT_FAILURE";
export const FETCH_OUR_CLIENT = "FETCH_OUR_CLIENT";
export const FETCHED_OUR_CLIENT = "FETCHED_OUR_CLIENT";
export const OUR_CLIENT_DELETED = "OUR_CLIENT_DELETED";
