import {
  ADD_TESTIMONIAL,
  ADD_TESTIMONIAL_FAILURE,
  ADD_TESTIMONIAL_SUCCESS,
  FETCHED_TESTIMONIAL,
  FETCH_TESTIMONIAL,
  LOADING_START,
  LOADING_STOP,
  CLOSE_DRAWER,
  DELETE_TESTIMONIAL,
  FETCH_SINGLE_SUCCESS,
  UPDATE_TESTIMONIAL,
  UPDATE_TESTIMONIAL_SUCCESS,
  UPDATE_TESTIMONIAL_FAILURE,
} from "@Redux/actions/types";

import httpClient from "utils/httpClients";
import notify from "utils/notify";

export const fetchTestimonials =
  ({ pageSize, pageNumber }) =>
  (dispatch) => {
    dispatch({ type: LOADING_START });
    dispatch({ type: FETCH_TESTIMONIAL });
    httpClient
      .GET("/client_testimonials", { pageSize, pageNumber })
      .then((res) => {
        dispatch({ type: FETCHED_TESTIMONIAL, payload: res.data });
        dispatch({ type: LOADING_STOP });
      });
  };

export const addTestimonial = (data) => (dispatch) => {
  dispatch({ type: ADD_TESTIMONIAL });
  httpClient
    .POST("/client_testimonials", data)
    .then((res) => {
      notify.success("Testimonial added successfully");
      dispatch({ type: ADD_TESTIMONIAL_SUCCESS, payload: res.data });
      dispatch({ type: CLOSE_DRAWER });
    })
    .catch((err) => {
      notify.error(err);
      dispatch({ type: ADD_TESTIMONIAL_FAILURE });
    });
};

export const removeTestimonial = (id) => (dispatch) => {
  httpClient
    .REMOVE("/client_testimonials/" + id)
    .then((res) => {
      dispatch({
        type: DELETE_TESTIMONIAL,
        index: id,
      });
      notify.success("Testimonial deleted successfully");
    })
    .catch((err) => {
      notify.error(err);
    });
};

export const updateTestimonial = (data, id) => (dispatch) => {
  dispatch({ type: UPDATE_TESTIMONIAL });
  httpClient
    .PUT("/client_testimonials/" + id, data)
    .then((res) => {
      dispatch({ type: UPDATE_TESTIMONIAL_SUCCESS, payload: res.data.outdata });
      notify.success("Testimonial Updated Successfully.");
      dispatch({ type: CLOSE_DRAWER });
    })
    .catch((err) => {
      notify.error(err);
      dispatch({ type: UPDATE_TESTIMONIAL_FAILURE });
    });
};
export const fetchSingleTestimonial = (id) => (dispatch) => {
  httpClient
    .GET(`/client_testimonials/${id}`)
    .then((res) => {
      dispatch({ type: FETCH_SINGLE_SUCCESS, payload: res.data });
    })
    .catch((error) => {});
};
