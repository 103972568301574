import React from "react"
import { Button } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import { useDispatch } from "react-redux"
import { openDrawer } from "@Redux/actions/drawer.action"
import BlogTable from "./components/BlogTable.component"
import BlogForm from "./components/BlogForm.component"

const BlogPage = () => {
  const dispatch = useDispatch()

  const addFunc = () => {
    dispatch(openDrawer("Add Working Principle", <BlogForm />))
  }
  return (
    <div className="containers">
      <h6>
        <span className="heading">BLOGS</span>
      </h6>

      <div className="add-btn-justify">
        <Button onClick={addFunc} type="primary" icon={<PlusOutlined />}>
          ADD BLOGS
        </Button>
      </div>
      <BlogTable />
    </div>
  )
}

export default BlogPage
