import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row, Table, Popconfirm } from "antd";
import {
  fetchTestimonials,
  removeTestimonial,
} from "@Redux/actions/testimonial.action";
import Loading from "@Components/Loading.component";
import { fetchAllServices } from "@Redux/actions/service.action";
import Parser from "html-react-parser";
import { openDrawer } from "@Redux/actions/drawer.action";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import TestimonialForm from "./TestimonialsForm";
const TestimonialsTable = () => {
  const dispatch = useDispatch();
  let { testimonials, total } = useSelector((state) => state.testimonial);
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(fetchTestimonials({ pageSize: 10, pageNumber: 1 }));
  }, [dispatch]);

  useEffect(() => {
    const mapdata = testimonials?.map((item, i) => {
      return {
        key: i,
        sn: i + 1,
        name: item.name,
        image: item,
        company: item.company,
        service_id: item.service_id,
        description: item.description,
        action: item.id,
      };
    });

    setData(mapdata);
  }, [testimonials]);
  const { serviceList } = useSelector((state) => state.services);

  useEffect(() => {
    dispatch(fetchAllServices());
  }, [dispatch]);

  const onDeleteTestimonial = (id) => {
    dispatch(removeTestimonial(id));
  };
  const onUpdateTestimonial = (id) => {
    dispatch(
      openDrawer(
        "Update Testimonial",
        <TestimonialForm update={true} id={id} />
      )
    );
  };
  const columns = [
    {
      title: "SN",
      dataIndex: "sn",
      sorter: (a, b) => a.age - b.age,
      width: "5%",
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      width: "10%",
      render: (data) =>
        data?.image && (
          <img
            src={`${process.env.REACT_APP_FILE_URL}/images/${data?.image}`}
            className="award-image"
            alt={data?.name}
          />
        ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "15%",
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      width: "20%",
    },
    {
      title: "Service",
      dataIndex: "service_id",
      key: "service_id",
      width: "15%",
      render: (service_id) => {
        return serviceList.map((item) => {
          if (service_id == item.id) return item.name;
        });
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "20%",
      render: (description) => {
        return (
          <div className="line-clamp-4" style={{ width: "100%" }}>
            {Parser(description)}
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "10%",
      render: (id) => {
        return (
          <Row justify="center">
            <Col span={10}>
              <Button
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => onUpdateTestimonial(id)}
              />
            </Col>
            <Col span={10}>
              <Popconfirm
                placement="left"
                title={"Are you sure?"}
                onConfirm={() => {
                  onDeleteTestimonial(id);
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button shape="circle" danger icon={<DeleteOutlined />} />
              </Popconfirm>
            </Col>
          </Row>
        );
      },
    },
  ];
  const changePagination = (page, pageSize) => {
    dispatch(fetchTestimonials({ pageSize: pageSize, pageNumber: page }));
  };
  return (
    <>
      <Loading>
        <Table
          bordered
          columns={columns}
          dataSource={data}
          pagination={{
            defaultCurrent: 1,
            total: total,
            // current: pagenumber,
            pageSize: 10,
            onChange: changePagination,
          }}
        />
      </Loading>
    </>
  );
};
export default TestimonialsTable;
