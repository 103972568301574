import { OPEN_DRAWER, CLOSE_DRAWER } from "./../actions/types"

const initialState = {
  open: false,
  element: null,
  title: "",
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_DRAWER:
      return {
        ...state,
        open: true,
        title: action.title,
        element: action.element,
      }
    case CLOSE_DRAWER:
      return {
        ...state,
        open: false,
        title: "",
        element: null,
      }
    default:
      return { ...state }
  }
}

export default reducer
