import {
  FETCH_TEAM,
  FETCHED_TEAM,
  ADD_TEAM,
  ADD_TEAM_SUCCESS,
  ADD_TEAM_FAILURE,
  DELETE_TEAM,
  LOADING_START,
  LOADING_STOP,
  CLOSE_DRAWER,
  UPDATE_TEAM,
  UPDATE_TEAM_SUCCESS,
  UPDATE_TEAM_FAILURE,
  FETCH_SINGLE_SUCCESS,
} from "./types"
import httpClient from "utils/httpClients"
import notify from "utils/notify"

export const fetchTeam =
  ({ page, limit }) =>
  (dispatch) => {
    dispatch({ type: LOADING_START })

    dispatch({ type: FETCH_TEAM })
    httpClient.GET("/team", { page, limit }).then((res) => {
      dispatch({ type: FETCHED_TEAM, payload: res.data })
      dispatch({ type: LOADING_STOP })
    })
  }
export const addTeam = (data) => (dispatch) => {
  dispatch({ type: ADD_TEAM })
  httpClient
    .POST("/team", data)
    .then((res) => {
      notify.success("Team added successfully")
      dispatch({ type: ADD_TEAM_SUCCESS, payload: res.data })
      dispatch({ type: CLOSE_DRAWER })
    })
    .catch((err) => {
      notify.error(err)
      dispatch({ type: ADD_TEAM_FAILURE })
    })
}

export const removeTeam = (id) => (dispatch) => {
  httpClient
    .REMOVE(`/team/${id}`)
    .then((res) => {
      dispatch({
        type: DELETE_TEAM,
        index: id,
      })
      notify.success("Team Member Deleted Successfully")
    })
    .catch((err) => {
      notify.error(err)
    })
}

export const updateTeam = (data, id) => (dispatch) => {
  dispatch({ type: UPDATE_TEAM })
  httpClient
    .PUT("/team/" + id, data)
    .then((res) => {
      dispatch({ type: UPDATE_TEAM_SUCCESS, payload: res.data })
      notify.success("Team Updated Successfully.")
      dispatch({ type: CLOSE_DRAWER })
    })
    .catch((err) => {
      notify.error(err)
      dispatch({ type: UPDATE_TEAM_FAILURE })
    })
}
export const fetchSingleTeam = (id) => (dispatch) => {
  httpClient
    .GET(`/team/${id}`)
    .then((res) => {
      dispatch({ type: FETCH_SINGLE_SUCCESS, payload: res.data })
    })
    .catch((error) => {})
}
