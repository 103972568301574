import React, { useEffect } from "react";
import AboutUsContentForm from "./Components/AboutUsContetnForm";

const AboutUsContent = () => {
  return (
    <div className="containers">
      <h6>
        <span className="heading">About Us Contents</span>
      </h6>
      <div className="form-container">
        <AboutUsContentForm />
      </div>
    </div>
  );
};

export default AboutUsContent;
