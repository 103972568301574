import React from "react"
import { Button } from "antd"
import { useDispatch } from "react-redux"
import { PlusOutlined } from "@ant-design/icons"
import ServiceProcessTable from "./components/ServiceProcessTable.component"
import { openDrawer } from "@Redux/actions/drawer.action"
import ServiceProcessStepForm from "./components/ServiceProcessForm.component"
const ServiceProcessStep = () => {
  const dispatch = useDispatch()

  const addStepFunc = () => {
    dispatch(openDrawer("Add Service Process", <ServiceProcessStepForm />))
  }
  return (
    <div className="containers">
      <h6>
        <span className="heading">SERVICE PROCESS STEP</span>
      </h6>

      <div className="add-btn-justify">
        <Button type="primary" onClick={addStepFunc} icon={<PlusOutlined />}>
          ADD STEP
        </Button>
      </div>
      <ServiceProcessTable />
    </div>
  )
}

export default ServiceProcessStep
