import React, { useState, useEffect } from "react";
import { Form, Input, Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import { useDispatch, useSelector } from "react-redux";
import Btn from "@Components/Button.component";
import { addSEO, fetchSEO, updateSEO } from "@Redux/actions/seo.action";

const SEOFORM = () => {
  const [form] = Form.useForm();
  const [fileImg, setFileImg] = useState(null);
  const [img, setImage] = useState(null);
  const [loadingImg, setLoadingImg] = useState(false);

  const dispatch = useDispatch();

  let { seo, addBtnLoading } = useSelector((state) => state.seo);

  useEffect(() => {
    dispatch(fetchSEO());
  }, [dispatch]);

  useEffect(() => {
    form.resetFields();
    if (seo.length > 0) {
      setImage(`${process.env.REACT_APP_FILE_URL}/images/${seo[0]?.image}`);
    }
  }, [seo]);

  const initialValue = {
    title: seo[0]?.title || "",
    description: seo[0]?.description || "",
  };

  const beforeUpload = (file, fileList) => {
    const isLt2M = file.size / 1024 / 1024 < 1.5;
    if (!isLt2M) {
      message.error("Image must smaller than 1.5MB!");
      return false;
    }
    setFileImg(fileList);
    setImage(URL.createObjectURL(fileList[0]));
    return true;
  };

  const uploadButton = (
    <div className="upload-button">
      <div>
        {loadingImg ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    </div>
  );

  return (
    <>
      <Form
        initialValues={initialValue}
        onFinish={(data) => {
          let formData = new FormData();
          for (var key in data) {
            formData.append(key, data[key]);
          }
          formData.append("image", fileImg[0]);

          if (seo.length > 0) {
            dispatch(updateSEO(formData, seo[0]?.id));
          } else {
            dispatch(addSEO(formData));
          }
        }}
        layout="vertical"
        form={form}
      >
        <Form.Item
          name="file"
          valuePropName="filelist"
          style={{ textAlign: "center" }}
          rules={[{ required: true, message: "Invalid Input!" }]}
        >
          <div className="justify-center">
            <ImgCrop modalTitle="CROP IMAGE" aspect={1.91 / 1}>
              <Upload
                style={{
                  width: "382px",
                  height: "200px",
                  marginBottom: "10px",
                  cursor: "pointer",
                }}
                showUploadList={false}
                className="avatar-uploader"
                accept="image/jpg,image/jpeg,image/webp"
                beforeUpload={beforeUpload}
              >
                {img ? (
                  <img
                    src={img}
                    style={{
                      width: "382px",
                      height: "200px",
                      marginBottom: "20px",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </ImgCrop>
          </div>
        </Form.Item>
        <label className="form-label">SEO TITLE</label>
        <Form.Item
          rules={[{ required: true, message: "Invalid Input!" }]}
          name="title"
        >
          <Input placeholder="SEO TITLE" />
        </Form.Item>
        <label className="form-label">SEO DESCRIPTION</label>
        <Form.Item
          rules={[{ required: true, message: "Invalid Input!" }]}
          name="description"
        >
          <Input.TextArea rows={6} placeholder="SEO DESCRIPTION" />
        </Form.Item>

        <div
          style={{ display: "flex", width: "100%", justifyContent: "right" }}
        >
          <Form.Item>
            <Btn addBtnLoading={addBtnLoading} />
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default SEOFORM;
