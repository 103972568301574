import React from "react"
import ReactQuill from "react-quill"

const TextEditor = ({ modules, formats, value, placeholder, onChange }) => {
  return (
    <ReactQuill
      modules={modules}
      theme="snow"
      formats={formats}
      value={value || ""}
      placeholder={placeholder}
      onChange={onChange}
    />
  )
}

export default TextEditor
