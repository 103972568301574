import React, { useEffect } from "react";
import { Form, Input } from "antd";
import TextEditor from "@Components/TextEditor.component";
import { useDispatch, useSelector } from "react-redux";
import {
  addHowWeWork,
  fetchSingleHowWeWork,
  updateHowWeWork,
} from "@Redux/actions/howWeWork.action";
import Btn from "@Components/Button.component";

const HowWeWorkForm = ({ update, id }) => {
  const [form] = Form.useForm();
  const modules = {
    toolbar: [["bold", "italic", "underline", "link"]],
  };
  const formats = ["bold", "italic", "underline", "link"];

  const dispatch = useDispatch();
  const { addBtnLoading, formreset, hww } = useSelector((state) => state.hhws);

  useEffect(() => {
    form.resetFields();
  }, [hww, form, update]);
  // -------------------------------

  useEffect(() => {
    if (update && id) {
      dispatch(fetchSingleHowWeWork(id));
    }
  }, [id, update, dispatch]);

  const initialValue = update
    ? {
        title: hww?.title || "",
        description: hww?.description || "",
        collapseTitle: hww?.collapseTitle || "",
      }
    : {};

  if (formreset) form.resetFields();

  const addHowWeWorks = (data) => {
    if (update) {
      data.id = hww.id;
      dispatch(updateHowWeWork(data));
    } else {
      dispatch(addHowWeWork(data));
    }
  };

  return (
    <>
      <Form
        onFinish={(data) => {
          addHowWeWorks(data);
        }}
        layout="vertical"
        form={form}
        name="team_form"
        initialValues={initialValue}
      >
        <label className="form-label">Title</label>
        <Form.Item
          rules={[{ required: true, message: "Invalid Input!" }]}
          name="title"
        >
          <Input placeholder="Title" />
        </Form.Item>
        <label className="form-label">Collaps Title</label>
        <Form.Item
          rules={[{ required: true, message: "Invalid Input!" }]}
          name="collapseTitle"
        >
          <Input placeholder="Collaps Title" />
        </Form.Item>
        <label className="form-label">DESCRIPTION</label>
        <Form.Item
          rules={[
            {
              validator: (_, value) =>
                value && value !== "<p><br></p>"
                  ? Promise.resolve()
                  : Promise.reject(new Error("Invalid Input!")),
            },
          ]}
          name="description"
        >
          <TextEditor
            modules={modules}
            formats={formats}
            placeholder="Describe How We Work"
          />
        </Form.Item>
        <div
          style={{ display: "flex", width: "100%", justifyContent: "right" }}
        >
          <Form.Item>
            <Btn addBtnLoading={addBtnLoading} />
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default HowWeWorkForm;
