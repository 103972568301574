import {
  FETCH_SEO,
  FETCHED_SEO,
  ADD_SEO,
  ADD_SEO_SUCCESS,
  ADD_SEO_FAILURE,
  UPDATE_SEO,
  UPDATE_SEO_SUCCESS,
  UPDATE_SEO_FAILURE,
} from "@Redux/actions/types";

const initialState = {
  seo: [],
  loading: false,
  addBtnLoading: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_SEO:
      return {
        ...state,
        addBtnLoading: true,
      };
    case ADD_SEO_SUCCESS:
      return {
        ...state,
        seo: [action.payload, ...state.seo],
        addBtnLoading: false,
      };
    case ADD_SEO_FAILURE:
      return {
        ...state,
        addBtnLoading: false,
      };

    case FETCH_SEO:
      return {
        ...state,
        loading: true,
      };
    case FETCHED_SEO:
      return {
        ...state,
        seo: action.payload,
        loading: false,
      };

    case UPDATE_SEO:
      return {
        ...state,
        addBtnLoading: true,
      };

    case UPDATE_SEO_SUCCESS:
      const data = state.seo.map((item) => {
        if (item.id !== action.payload.id) {
          return item;
        } else {
          return {
            ...item,
            ...action.payload,
          };
        }
      });
      return {
        ...state,
        seo: data,
        addBtnLoading: false,
      };
    case UPDATE_SEO_FAILURE:
      return {
        ...state,
        addBtnLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}

export default reducer;
