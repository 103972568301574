import {
  LOGOUT_USER,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGIN_START,
} from "./../actions/types"

const initialValue = {
  message: null,
  loggedIn: !!localStorage.getItem("token"),
  loading: false,
}

function reducer(state = initialValue, action) {
  switch (action.type) {
    case LOGIN_START:
      return {
        loading: true,
      }
    case LOGIN_SUCCESS:
      localStorage.setItem("token", action.payload)
      return {
        loading: false,
        loggedIn: true,
      }
    case LOGIN_FAIL:
      return {
        loading: false,
        message: action.message,
      }
    case LOGOUT_USER:
      localStorage.clear()
      return {
        loggedIn: false,
      }
    default:
      return {
        ...state,
      }
  }
}

export default reducer
