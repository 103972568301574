import React, { useEffect, useState } from "react";
import { Form, Input, Button, Upload, message, Divider } from "antd";
import { useDispatch } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import TextEditor from "@Components/TextEditor.component";
import SelectInput from "@Components/TagsInput.component";
import { fetchSingleService } from "@Redux/actions/service.action";
import { useSelector } from "react-redux";
import { addService, updateService } from "@Redux/actions/service.action";
const ServiceForm = ({ update, id }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [fileImg, setFileImg] = useState(null);
  const [gifImg, setFileGif] = useState(null);
  const [gif_img, setGif] = useState(null);
  const [img, setImage] = useState(null);
  const { service, loadingBtn } = useSelector((state) => state.services);
  // -------------------------

  useEffect(() => {
    form.resetFields();
    if (update) {
      setImage(`${process.env.REACT_APP_FILE_URL}/images/${service?.image}`);
      setGif(`${process.env.REACT_APP_FILE_URL}/images/${service?.gif}`);
    }
    return () => {
      setImage(null);
      setGif(null);
    };
  }, [service, form, update]);
  // -------------------------------

  useEffect(() => {
    if (update && id) {
      dispatch(fetchSingleService(id));
      // form.setFieldsValue({

      // })
    }
  }, [id, update, dispatch]);

  const uploadButton = (
    <div className="upload-button">
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    </div>
  );

  const modules = {
    toolbar: [["bold", "italic", "underline", "link"]],
  };

  const formats = ["bold", "italic", "underline", "link"];

  const beforeUploadGif = (file, fileList) => {
    setGif(URL.createObjectURL(fileList[0]));
    setFileGif(fileList);
    return true;
  };

  const beforeUpload = (file, fileList) => {
    const isLt2M = file.size / 1024 / 1024 < 1.5;
    if (!isLt2M) {
      message.error("Image must smaller than 1.5MB!");
      return false;
    }
    setFileImg(fileList);
    setImage(URL.createObjectURL(fileList[0]));
    return true;
  };

  const initialValue = update
    ? {
        file: service?.image || "",
        gif_file: service?.gif || "",
        name: service?.name || "",
        title: service?.title || "",
        sub_service: service?.sub_service
          ? JSON.parse(service?.sub_service).split(",")
          : [],
        description: service?.description || "",
        service_quotes: service?.service_quotes || "",
        p_title: service?.p_title || "",
        p_quote: service?.p_quote || "",
        p_description: service?.p_description || "",
      }
    : {};

  const onFinish = (data) => {
    const formData = new FormData();

    for (var key in data) {
      formData.append(key, data[key]);
    }
    if (fileImg) {
      formData.append("image", fileImg[0]);
    }
    if (gifImg) {
      formData.append("gif", gifImg[0]);
    }

    if (update) {
      dispatch(updateService(id, formData));
    } else {
      dispatch(addService(formData));
    }
  };

  return (
    <>
      <Form
        onFinish={onFinish}
        initialValues={initialValue}
        layout="vertical"
        form={form}
        name="services_form"
      >
        <label className="form-label">IMAGE</label>
        <Form.Item
          name="file"
          // valuePropName="filelist"
          // getValueFromEvent={normFile}
          // noStyle
          rules={[{ required: true, message: "Invalid Input!" }]}
        >
          <div>
            <ImgCrop
              style={{ zIndex: "2001" }}
              modalTitle="CROP IMAGE"
              aspect={4 / 4}
            >
              <Upload
                style={{
                  width: "200px",
                  height: "200px",
                  marginBottom: "10px",
                  cursor: "pointer",
                }}
                //   listType="picture-card"
                showUploadList={false}
                className="avatar-uploader"
                // type="drag"
                accept="image/jpg,image/jpeg,image/webp"
                beforeUpload={beforeUpload}
              >
                {img ? (
                  <img
                    src={img}
                    style={{
                      width: "200px",
                      height: "200px",
                      marginBottom: "20px",
                      cursor: "pointer",
                    }}
                    alt="file input"
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </ImgCrop>
          </div>
        </Form.Item>

        <label className="form-label">GIF</label>
        <Form.Item
          rules={[{ required: true, message: "Invalid Input!" }]}
          name="gif_file"
          valuePropName="filelist"
        >
          <Upload
            style={{
              width: "200px",
              height: "200px",
              marginBottom: "10px",
              cursor: "pointer",
            }}
            showUploadList={false}
            accept="image/svg+xml,image/gif"
            beforeUpload={beforeUploadGif}
          >
            {gif_img ? (
              <img
                src={gif_img}
                style={{
                  width: "100px",
                  height: "100px",
                  marginBottom: "20px",
                  cursor: "pointer",
                }}
                alt="gif file"
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>
        <label className="form-label">SERVICE NAME</label>
        <Form.Item
          rules={[{ required: true, message: "Invalid Input!" }]}
          name="name"
        >
          <Input placeholder="Enter Service Name" />
        </Form.Item>
        <label className="form-label">TITLE</label>
        <Form.Item
          rules={[{ required: true, message: "Invalid Input!" }]}
          name="title"
        >
          <Input placeholder="Title" />
        </Form.Item>
        <label className="form-label">QUOTE</label>
        <Form.Item
          rules={[{ required: true, message: "Invalid Input!" }]}
          name="service_quotes"
        >
          <Input placeholder="Enter Quote" />
        </Form.Item>
        <label className="form-label">SUB SERVICE</label>
        <Form.Item
          rules={[{ required: true, message: "Invalid Input!" }]}
          name="sub_service"
        >
          <SelectInput
            placeholder="Select SubService"
            options={service?.sub_service || []}
          />
        </Form.Item>
        <label className="form-label"> DESCRIPTION</label>
        <Form.Item
          rules={[
            {
              validator: (_, value) =>
                value && value !== "<p><br></p>"
                  ? Promise.resolve()
                  : Promise.reject(new Error("Invalid Input!")),
            },
          ]}
          name="description"
        >
          <TextEditor
            modules={modules}
            formats={formats}
            placeholder="Describe Service"
          />
        </Form.Item>

        {/*  */}
        <Divider />
        <label className="form-label"> PROCESS TITLE</label>
        <Form.Item
          rules={[{ required: true, message: "Invalid Input!" }]}
          name="p_title"
        >
          <Input placeholder="Service Process  Title" />
        </Form.Item>
        <label className="form-label"> PROCESS QUOTE</label>
        <Form.Item
          rules={[{ required: true, message: "Invalid Input!" }]}
          name="p_quote"
        >
          <Input placeholder="Process Quotes" />
        </Form.Item>
        <label className="form-label">PROCESS DESCRIPTION</label>
        <Form.Item
          rules={[{ required: true, message: "Invalid Input!" }]}
          name="p_description"
        >
          <TextEditor
            modules={modules}
            formats={formats}
            placeholder="PROCESS DESCRIPTION"
          />
        </Form.Item>

        <div
          style={{ display: "flex", width: "100%", justifyContent: "right" }}
        >
          <Form.Item>
            <Button
              type="primary"
              loading={loadingBtn}
              size="large"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default ServiceForm;
