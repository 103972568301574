import React, { useState, useEffect } from "react";
import { Form, Input, Select, message, Upload } from "antd";
import TextEditor from "@Components/TextEditor.component";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import {
  addTestimonial,
  fetchSingleTestimonial,
  updateTestimonial,
} from "@Redux/actions/testimonial.action";
import { fetchAllServices } from "@Redux/actions/service.action";
import { useDispatch, useSelector } from "react-redux";
import Btn from "@Components/Button.component";

const TestimonialForm = ({ update, id }) => {
  const [form] = Form.useForm();
  const [fileImg, setFileImg] = useState(null);
  const [img, setImage] = useState(null);
  const [loadingImg, setLoadingImg] = useState(false);

  const beforeUpload = (file, fileList) => {
    const isLt2M = file.size / 1024 / 1024 < 1.5;
    if (!isLt2M) {
      message.error("Image must smaller than 1.5MB!");
      return false;
    }

    setFileImg(fileList);
    setImage(URL.createObjectURL(fileList[0]));
    return true;
  };

  const dispatch = useDispatch();
  const { addBtnLoading, testimonial } = useSelector(
    (state) => state.testimonial
  );

  const { serviceList } = useSelector((state) => state.services);
  useEffect(() => {
    dispatch(fetchAllServices());
  }, [dispatch]);

  useEffect(() => {
    form.resetFields();
    if (update && testimonial) {
      setImage(
        `${process.env.REACT_APP_FILE_URL}/images/${testimonial?.image}`
      );
    }
  }, [testimonial, form, update]);
  // -------------------------------

  useEffect(() => {
    if (update && id) {
      dispatch(fetchSingleTestimonial(id));
    }
  }, [id, update, dispatch]);

  const initialValue = update
    ? {
        name: testimonial?.name || "",
        company_name: testimonial?.company || "",
        description: testimonial?.description || "",
        service: testimonial?.service_id,
      }
    : {};
  const uploadButton = (
    <div className="upload-button">
      <div>
        {loadingImg ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    </div>
  );
  const modules = {
    toolbar: [["bold", "italic", "underline"]],
  };

  const formats = ["bold", "italic", "underline"];

  const handleFormSubmit = (data) => {
    let formData = new FormData();

    formData.append("image", fileImg[0]);
    for (var key in data) {
      formData.append(key, data[key]);
    }
    if (update) {
      dispatch(updateTestimonial(formData, testimonial?.id));
    } else {
      dispatch(addTestimonial(formData));
    }
  };
  return (
    <>
      <Form
        onFinish={(data) => {
          handleFormSubmit(data);
        }}
        layout="vertical"
        form={form}
        initialValues={initialValue}
        name="testimonial_form"
      >
        <label className="form-label">CLIENT IMAGE</label>
        <Form.Item
          name="file"
          valuePropName="filelist"
          // getValueFromEvent={normFile}
          // noStyle
          rules={[{ required: true, message: "Invalid Input!" }]}
        >
          <div>
            <ImgCrop
              style={{ zIndex: "2001" }}
              modalTitle="CROP IMAGE"
              aspect={4 / 4}
            >
              <Upload
                style={{
                  width: "200px",
                  height: "200px",
                  marginBottom: "10px",
                  cursor: "pointer",
                }}
                //   listType="picture-card"
                showUploadList={false}
                className="avatar-uploader"
                // type="drag"
                accept="image/jpg,image/jpeg,image/webp"
                // customRequest={customRequest}
                // onChange={onChange}
                beforeUpload={beforeUpload}
              >
                {img ? (
                  <img
                    src={img}
                    style={{
                      width: "200px",
                      height: "200px",
                      marginBottom: "20px",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </ImgCrop>
          </div>
        </Form.Item>
        <label className="form-label">NAME</label>
        <Form.Item name="name">
          <Input placeholder="Enter Client Name" />
        </Form.Item>
        <label className="form-label">COMPANY NAME & POSITION</label>
        <Form.Item name="company_name">
          <Input placeholder="Enter Company Name & Position" />
        </Form.Item>

        <label className="form-label">SERVICES</label>
        <Form.Item
          rules={[{ required: true, message: "Invalid Input!" }]}
          name="service"
        >
          <Select mode="single" placeholder="Select Services">
            {serviceList?.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <label className="form-label"> DESCRIPTION</label>
        <Form.Item
          rules={[
            {
              validator: (_, value) =>
                value && value !== "<p><br></p>"
                  ? Promise.resolve()
                  : Promise.reject(new Error("Invalid Input!")),
            },
          ]}
          name="description"
        >
          <TextEditor
            modules={modules}
            formats={formats}
            placeholder="Description"
          />
        </Form.Item>

        <div
          style={{ display: "flex", width: "100%", justifyContent: "right" }}
        >
          <Form.Item>
            <Btn addBtnLoading={addBtnLoading} />
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default TestimonialForm;
