import { Button, Form, Input } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "@Redux/actions/profile.action";

const ProfilePage = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { addBtnLoading } = useSelector((state) => state.profile);

  const onChangePassword = (data) => {
    dispatch(changePassword(data));
  };

  return (
    <div className="containers">
      <h6>
        <span className="heading">OUR PORTFOLIO</span>
      </h6>
      <div className="profile">
        <div className="profile-form-container">
          <Form
            onFinish={(data) => {
              onChangePassword(data);
            }}
            layout="vertical"
            form={form}
            name="profile_form"
          >
            <label className="form-label">OLD PASSWORD</label>
            <Form.Item
              name="old_password"
              rules={[{ required: true, message: "Invalid Input!" }]}
            >
              <Input.Password placeholder="Enter Old Password" />
            </Form.Item>
            <label className="form-label">NEW PASSWORD</label>
            <Form.Item
              name="password"
              rules={[{ required: true, message: "Invalid Input!" }]}
            >
              <Input.Password placeholder="Enter New Password" />
            </Form.Item>
            <Form.Item>
              {addBtnLoading ? (
                <Button
                  style={{ marginTop: "25px" }}
                  type="primary"
                  block
                  htmlType="submit"
                >
                  REQUESTING
                </Button>
              ) : (
                <Button
                  style={{ marginTop: "25px" }}
                  type="primary"
                  block
                  htmlType="submit"
                >
                  REQUEST
                </Button>
              )}
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
