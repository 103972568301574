import {
  ADD_SERVICE_START,
  ADD_SERVICE_SUCCESS,
  ADD_SERVICE_FAIL,
  FETCH_SERVICES_SUCCESS,
  FETCH_SERVICES_START,
  FETCH_SERVICE_SUCCESS,
  UPDATE_SERVICE_SUCCESS,
  DELETE_SERVICE,
  FETCH_ALL_SERVICES,
} from "./../actions/types";

const initialValue = {
  services: [],
  loading: false,
  loadingBtn: false,
  total: 0,
  service: {},
  serviceList: [],
};

function reducer(state = initialValue, action) {
  switch (action.type) {
    case FETCH_SERVICE_SUCCESS:
      return {
        ...state,
        service: action.payload,
      };
    case FETCH_SERVICES_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SERVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        services: action.payload,
        total: action.total,
      };

    case ADD_SERVICE_START:
      return {
        ...state,
        loadingBtn: true,
      };
    case ADD_SERVICE_SUCCESS:
      return {
        ...state,
        loadingBtn: false,
        services: [action.payload, ...state.services],
        total: state.total + 1,
      };
    case ADD_SERVICE_FAIL:
      return {
        ...state,
        loadingBtn: false,
      };
    case UPDATE_SERVICE_SUCCESS:
      const formatData = state.services.map((item) => {
        if (item.id !== action.payload.id) {
          return item;
        } else {
          return {
            ...item,
            ...action.payload,
          };
        }
      });
      return {
        ...state,
        loadingBtn: false,
        services: formatData,
      };

    case DELETE_SERVICE:
      const itemDeleted = state.services.filter(
        (item) => item.id !== action.id,
      );
      return {
        ...state,
        services: itemDeleted,
      };

    case FETCH_ALL_SERVICES:
      return {
        ...state,
        serviceList: action.payload,
      };
    default: {
      return {
        ...state,
      };
    }
  }
}

export default reducer;
