import React, { useState, useEffect } from "react";
import { Form, Input, Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import { fetchAbout, addAbout, updateAbout } from "@Redux/actions/about.action";
import { useDispatch, useSelector } from "react-redux";
import Btn from "@Components/Button.component";

const AboutUsContentForm = () => {
  const [form] = Form.useForm();
  const [fileImg, setFileImg] = useState(null);
  const [img, setImage] = useState(null);
  const [loadingImg, setLoadingImg] = useState(false);

  const dispatch = useDispatch();

  let { aboutus, addBtnLoading } = useSelector((state) => state.aboutus);

  useEffect(() => {
    dispatch(fetchAbout());
  }, [dispatch]);

  useEffect(() => {
    form.resetFields();
    if (aboutus.length > 0) {
      setImage(`${process.env.REACT_APP_FILE_URL}/images/${aboutus[0]?.image}`);
    }
  }, [aboutus]);

  const initialValue = {
    title: aboutus[0]?.title || "",
    description: aboutus[0]?.description || "",
    video: aboutus[0]?.video || "",
    how_we_work: aboutus[0]?.how_we_work || "",
  };

  const beforeUpload = (file, fileList) => {
    const isLt2M = file.size / 1024 / 1024 < 1.5;
    if (!isLt2M) {
      message.error("Image must smaller than 1.5MB!");
      return false;
    }
    setFileImg(fileList);
    setImage(URL.createObjectURL(fileList[0]));
    return true;
  };

  const uploadButton = (
    <div className="upload-button">
      <div>
        {loadingImg ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    </div>
  );

  return (
    <>
      <Form
        initialValues={initialValue}
        onFinish={(data) => {
          let formData = new FormData();
          for (var key in data) {
            formData.append(key, data[key]);
          }
          formData.append("image", fileImg[0]);
          if (aboutus.length > 0) {
            dispatch(updateAbout(formData, aboutus[0]?.id));
          } else {
            dispatch(addAbout(formData));
          }
        }}
        layout="vertical"
        form={form}
      >
        <Form.Item
          name="file"
          valuePropName="filelist"
          style={{ textAlign: "center" }}
          rules={[{ required: true, message: "Invalid Input!" }]}
        >
          <div className="justify-center">
            <ImgCrop modalTitle="CROP IMAGE" aspect={4 / 5}>
              <Upload
                style={{
                  width: "200px",
                  height: "200px",
                  marginBottom: "10px",
                  cursor: "pointer",
                }}
                showUploadList={false}
                className="avatar-uploader"
                accept="image/jpg,image/jpeg,image/webp"
                beforeUpload={beforeUpload}
              >
                {img ? (
                  <img
                    src={img}
                    style={{
                      width: "200px",
                      height: "220px",
                      marginBottom: "20px",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </ImgCrop>
          </div>
        </Form.Item>
        <label className="form-label">ABOUT US PAGE TITLE</label>
        <Form.Item
          rules={[{ required: true, message: "Invalid Input!" }]}
          name="title"
        >
          <Input placeholder="ABOUT US PAGE TITLE" />
        </Form.Item>
        <label className="form-label">ABOUT US DESCRIPTION</label>
        <Form.Item
          rules={[{ required: true, message: "Invalid Input!" }]}
          name="description"
        >
          <Input.TextArea rows={6} placeholder="ABOUT US DESCRIPTION" />
        </Form.Item>
        <label className="form-label">VIDEO LINK</label>
        <Form.Item
          rules={[{ required: true, message: "Invalid Input!" }]}
          name="video"
        >
          <Input placeholder="ABOUT US VIDEO LINK" />
        </Form.Item>
        <label className="form-label">HOW WE WORK </label>
        <Form.Item
          rules={[{ required: true, message: "Invalid Input!" }]}
          name="how_we_work"
        >
          <Input.TextArea rows={6} placeholder="HOW WE WORK" />
        </Form.Item>
        <div
          style={{ display: "flex", width: "100%", justifyContent: "right" }}
        >
          <Form.Item>
            <Btn addBtnLoading={addBtnLoading} />
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default AboutUsContentForm;
