import {
  ADD_TESTIMONIAL,
  ADD_TESTIMONIAL_FAILURE,
  ADD_TESTIMONIAL_SUCCESS,
  DELETE_TESTIMONIAL,
  FETCHED_TESTIMONIAL,
  FETCH_TESTIMONIAL,
  FETCH_SINGLE_SUCCESS,
  UPDATE_TESTIMONIAL,
  UPDATE_TESTIMONIAL_SUCCESS,
  UPDATE_TESTIMONIAL_FAILURE,
} from "@Redux/actions/types";

const initialState = {
  testimonials: [],
  total: 0,
  addBtnLoading: false,
  testimonial: {},
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TESTIMONIAL:
      return {
        ...state,
        addBtnLoading: true,
      };
    case ADD_TESTIMONIAL_SUCCESS:
      return {
        ...state,
        testimonials: [action.payload, ...state.testimonials],
        addBtnLoading: false,
      };
    case ADD_TESTIMONIAL_FAILURE:
      return {
        ...state,
        addBtnLoading: false,
      };

    case FETCH_TESTIMONIAL:
      return {
        ...state,
      };
    case FETCHED_TESTIMONIAL:
      return {
        ...state,
        testimonials: action.payload.result,
        total: action.payload.total,
      };
    case DELETE_TESTIMONIAL:
      const delTesti = state.testimonials.filter(
        (item) => item.id !== action.index
      );
      return {
        ...state,
        testimonials: delTesti,
      };
    case FETCH_SINGLE_SUCCESS:
      return {
        ...state,
        testimonial: action.payload,
      };
    case UPDATE_TESTIMONIAL:
      return {
        ...state,
        addBtnLoading: true,
      };

    case UPDATE_TESTIMONIAL_SUCCESS:
      const data = state.testimonials.map((item) => {
        if (item.id !== action.payload.id) {
          return item;
        } else {
          return {
            ...item,
            ...action.payload,
          };
        }
      });
      return {
        ...state,
        testimonials: data,
        addBtnLoading: false,
      };
    case UPDATE_TESTIMONIAL_FAILURE:
      return {
        ...state,
        addBtnLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}

export default reducer;
