import { message } from "antd"
import httpClients from "utils/httpClients"
import {
  FETCH_SERVICE_STEP_SUCCESS,
  ADD_SERVICE_STEP_START,
  ADD_SERVICE_STEP_SUCCESS,
  ADD_SERVICE_STEP_FAIL,
  LOADING_START,
  LOADING_STOP,
  CLOSE_DRAWER,
  FETCH_SINGLE_PROCESS,
  UPDATE_PROCESS_STEP,
  UPDATE_PROCESS_STEP_SUCCESS,
  UPDATE_PROCESS_STEP_FAIL,
  DELETED_PROCESS_LIST,
} from "./types"

export const updateProcessStep = (id, data) => (dispatch) => {
  dispatch({ type: UPDATE_PROCESS_STEP })
  httpClients
    .PUT(`/service-process-list/${id}`, data)
    .then((out) => {
      dispatch({ type: CLOSE_DRAWER })
      dispatch({ type: UPDATE_PROCESS_STEP_SUCCESS, payload: out.data })
      message.success("Content Updated")
    })
    .catch((error) => {
      message.error(error.response.data.message)
      dispatch({ type: UPDATE_PROCESS_STEP_FAIL })
    })
}

export const fetchServiceSte =
  ({ pageNumber, pageSize }) =>
  (dispatch) => {
    dispatch({ type: LOADING_START })
    httpClients
      .GET("/service-process-list", { pageNumber, pageSize })
      .then((out) => {
        dispatch({
          type: FETCH_SERVICE_STEP_SUCCESS,
          processes: out.data.rows,
          total: out.data.count,
        })
        dispatch({ type: LOADING_STOP })
      })
  }
export const fetchSingleStep = (id) => (dispatch) => {
  httpClients.GET(`/service-process-list/${id}`).then((out) => {
    dispatch({ type: FETCH_SINGLE_PROCESS, payload: out.data })
  })
}
export const addServiceStep = (data) => (dispatch) => {
  dispatch({ type: ADD_SERVICE_STEP_START })
  httpClients
    .POST("/service-process-list", data)
    .then((out) => {
      dispatch({ type: ADD_SERVICE_STEP_SUCCESS, payload: out.data })
      dispatch({ type: CLOSE_DRAWER })
      message.success("Service Process Added Successfully.")
    })
    .catch((error) => {
      dispatch({ type: ADD_SERVICE_STEP_FAIL })
      message.error(error.response.data.message)
    })
}

export const removeProcessList = (id) => (dispatch) => {
  httpClients
    .REMOVE(`/service-process-list/${id}`)
    .then((data) => {
      dispatch({ type: DELETED_PROCESS_LIST, id: id })
      message.success("Content Removed.")
    })
    .catch((error) => {
      message.error(error.response.data.message)
    })
}
