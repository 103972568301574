import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Parser from "html-react-parser";
import { openDrawer } from "@Redux/actions/drawer.action";
import { Button, Col, Row, Table, Popconfirm } from "antd";
import { TableSearchProps } from "hooks/TableSearch";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { fetchTeam, removeTeam } from "@Redux/actions/team.action";
import Loading from "@Components/Loading.component";
import TeamForm from "./TeamForm";

const TeamTable = () => {
  const dispatch = useDispatch();

  let { teams, total } = useSelector((state) => state.team);
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(fetchTeam({ pageSize: 1, pageNumber: 10 }));
  }, [dispatch]);

  const deleteTeam = (id) => {
    dispatch(removeTeam(id));
  };

  const updateTeamFunc = (id) => {
    dispatch(
      openDrawer("Update Team Member", <TeamForm update={true} id={id} />)
    );
  };
  const changePagination = (page, pageSize) => {
    dispatch(fetchTeam({ page: page, limit: pageSize }));
  };

  useEffect(() => {
    const mapdata = teams?.map((item, i) => {
      return {
        key: i,
        sn: item.sn,
        name: item.name,
        image: item,
        category: item.category,
        position: item.position,
        twitter: item.twitter_link,
        facebook: item.facebook_link,
        linkedin: item.linkedIn_link,
        github: item.github_link,
        description: item.description,
        action: item.id,
      };
    });

    setData(mapdata);
  }, [teams]);

  const columns = [
    {
      title: "SN",
      dataIndex: "sn",
      sorter: (a, b) => a.sn - b.sn,
      width: "2%",
    },
    {
      title: "Image",
      dataIndex: "image",
      width: "15%",
      render: (data) =>
        data?.image && (
          <img
            src={`${process.env.REACT_APP_FILE_URL}/images/${data?.image}`}
            className="award-image"
            alt={data?.name}
          />
        ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      filterMode: "menu",
      filterSearch: true,
      width: "17%",
      ...TableSearchProps("name"),
    },
    {
      title: "Category",
      dataIndex: "category",
      width: "15%",
    },
    {
      title: "Position",
      dataIndex: "position",
      width: "15%",
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (description) => {
        return (
          <div className="line-clamp-4" style={{ width: "100%" }}>
            {Parser(description)}
          </div>
        );
      },
      width: "25%",
    },

    {
      title: "Action",
      dataIndex: "action",
      width: "20%",
      render: (id) => {
        return (
          <Row justify="center">
            <Col span={10}>
              <Button
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => updateTeamFunc(id)}
              />
            </Col>
            <Col span={10}>
              <Popconfirm
                placement="left"
                title={"Are you sure?"}
                onConfirm={() => {
                  deleteTeam(id);
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button shape="circle" danger icon={<DeleteOutlined />} />
              </Popconfirm>
            </Col>
          </Row>
        );
      },
    },
  ];

  return (
    <>
      <Loading>
        <Table
          bordered
          columns={columns}
          dataSource={data}
          pagination={{
            defaultCurrent: 1,
            total: total,
            // current: pagenumber,
            pageSize: 10,
            onChange: changePagination,
          }}
        />{" "}
      </Loading>
    </>
  );
};
export default TeamTable;
