import React, { useState, useEffect } from "react";
import Parser from "html-react-parser";
import { Button, Col, Row, Table, Popconfirm } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  fetchHowWeWork,
  removeHowWeWork,
} from "@Redux/actions/howWeWork.action";
import { useDispatch, useSelector } from "react-redux";
import HowWeWorkForm from "./HowWeWorkForm";
import Loading from "@Components/Loading.component";
import { openDrawer } from "@Redux/actions/drawer.action";

const HowWeWorkTable = () => {
  const dispatch = useDispatch();
  let { hwws, total } = useSelector((state) => state.hhws);

  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(fetchHowWeWork({ pageSize: 1, pageNumber: 10 }));
  }, [dispatch]);

  const changePagination = (page, pageSize) => {
    dispatch(fetchHowWeWork({ page: page, limit: pageSize }));
  };

  useEffect(() => {
    const mapdata = hwws?.map((item, i) => {
      return {
        sn: i + 1,
        key: item.id,
        title: item.title,
        collapseTitle: item.collapseTitle,
        description: item.description,
        action: item.id,
      };
    });

    setData(mapdata);
  }, [hwws]);

  const deleteHwws = (id) => {
    dispatch(removeHowWeWork(id));
  };
  const updateHwws = (id) => {
    dispatch(
      openDrawer("Update How We Work", <HowWeWorkForm update={true} id={id} />)
    );
  };

  const columns = [
    {
      title: "SN",
      dataIndex: "sn",
      render: (value, item, index) => index + 1,
      width: "5%",
    },

    {
      title: "Title",
      dataIndex: "title",
      key: "name",
      width: "20%",
    },
    {
      title: "Collaps Title",
      dataIndex: "collapseTitle",
      width: "20%",
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (description) => {
        return (
          <div className="line-clamp-4" style={{ width: "100%" }}>
            {Parser(description)}
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "15%",
      render: (id) => {
        return (
          <Row justify="center">
            <Col span={10}>
              <Button
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => updateHwws(id)}
              />
            </Col>
            <Col span={10}>
              <Popconfirm
                placement="left"
                title={"Are you sure?"}
                onConfirm={() => {
                  deleteHwws(id);
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button shape="circle" danger icon={<DeleteOutlined />} />
              </Popconfirm>
            </Col>
          </Row>
        );
      },
    },
  ];

  return (
    <Loading>
      <Table
        bordered
        columns={columns}
        dataSource={data}
        pagination={{
          defaultCurrent: 1,
          total: total,
          // current: pagenumber,
          pageSize: 10,
          onChange: changePagination,
        }}
      />{" "}
    </Loading>
  );
};

export default HowWeWorkTable;
