import React, { useState, useEffect } from "react";
import { Table, Button, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import Loading from "@Components/Loading.component";
import {
  fetchTechnologies,
  removeTechnologies,
} from "@Redux/actions/techweoffer.action";

const TechnologyWeOfferTable = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const { technologies, total } = useSelector((state) => state.techweoffer);

  useEffect(() => {
    dispatch(fetchTechnologies({ page: 1, limit: 10 }));
  }, [dispatch]);

  useEffect(() => {
    const mapdata = technologies?.map((item, i) => {
      return {
        sn: i + 1,
        key: item.id,
        name: item.name,
        image: item,
        link: item.link,
        action: item.id,
      };
    });

    setData(mapdata);
  }, [technologies]);

  const deleteTech = (id) => {
    dispatch(removeTechnologies(id));
  };

  const changePagination = (page, pageSize) => {
    dispatch(fetchTechnologies({ page: page, limit: pageSize }));
  };
  const columns = [
    {
      title: "SN",
      dataIndex: "sn",
      width: "5%",
    },
    {
      title: "Title",
      dataIndex: "name",
      width: "30%",
    },
    {
      title: "Link",
      dataIndex: "link",
      width: "30%",
    },
    {
      title: "Image",
      dataIndex: "image",
      render: (data) =>
        data?.image && (
          <img
            src={`${process.env.REACT_APP_FILE_URL}/images/${data?.image}`}
            className="award-image"
            alt={data?.name}
          />
        ),

      width: "30%",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "20%",
      render: (id) => {
        return (
          <Popconfirm
            placement="left"
            title={"Are you sure?"}
            onConfirm={() => {
              deleteTech(id);
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button shape="circle" danger icon={<DeleteOutlined />} />
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <Loading>
      <Table
        bordered
        columns={columns}
        dataSource={data}
        pagination={{
          defaultCurrent: 1,
          total: total,

          pageSize: 10,
          onChange: changePagination,
        }}
      />
    </Loading>
  );
};

export default TechnologyWeOfferTable;
