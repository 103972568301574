import { toast } from "react-toastify";

function success(msg) {
  toast.success(msg);
}

function info(msg) {
  toast.info(msg);
}

function warning(msg) {
  toast.warning(msg);
}

function error(error) {
  let errMsg = "Something went wrong";
  const err = error.response;
  if (err && err.data) {
    errMsg = err.data.message;
  }
  toast.error(errMsg);
}

export default {
  success,
  info,
  warning,
  error,
};
