import {
  FETCH_AWARD,
  FETCHED_AWARD,
  ADD_AWARD_SUCCESS,
  ADD_AWARD_FAILURE,
  DELETE_AWARD,
  ADD_AWARD,
  LOADING_START,
  LOADING_STOP,
} from "./types"
import httpClient from "utils/httpClients"
import notify from "utils/notify"

export const fetchAward = () => (dispatch) => {
  dispatch({ type: LOADING_START })
  dispatch({ type: FETCH_AWARD })
  httpClient.GET("/award").then((res) => {
    dispatch({ type: FETCHED_AWARD, payload: res.data })
    dispatch({ type: LOADING_STOP })
  })
}
export const addAward = (data) => (dispatch) => {
  dispatch({ type: ADD_AWARD })
  httpClient
    .POST("/award", data)
    .then((res) => {
      dispatch({ type: ADD_AWARD_SUCCESS, payload: res.data })
      notify.success("Award added successfully")
    })
    .catch((err) => {
      notify.error(err)
      dispatch({ type: ADD_AWARD_FAILURE })
    })
}

export const removeAward = (id) => (dispatch) => {
  httpClient
    .REMOVE("/award/" + id)
    .then((res) => {
      dispatch({
        type: DELETE_AWARD,
        index: id,
      })
      notify.success("Award deleted successfully")
    })
    .catch((err) => {
      notify.error(err)
    })
}
