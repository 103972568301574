import React from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedLayouts from "../layouts/ProtectedLayouts";
import AboutUsContent from "@Pages/AboutUsContent";
import Dashboard from "@Pages/Dashboard";
import LogInPage from "@Pages/LogInPage";
import Team from "@Pages/Team";
import HowWeWork from "@Pages/HowWeWork";
import AwardPage from "@Pages/Award";
import Services from "@Pages/Services";

import ServiceProcessStep from "@Pages/ServiceProcessStep";
import ResultListPage from "@Pages/ResultList";
import PortfolioPage from "@Pages/Portfolio";
import Careers from "@Pages/Jobs";
import BlogPage from "@Pages/Blogs";
import ProfilePage from "@Pages/Profile";
import ContactPage from "@Pages/ContactPage";
import MessagePage from "@Pages/Messages";
import Testimonials from "@Pages/Testimonials";
import SEO from "@Pages/SEO";
import TechnologyWeOffer from "@Pages/TechnologyWeOffer";
import OurClients from "@Pages/OurClients";
const ApplicationRoute = () => {
  return (
    <Routes>
      <Route path="/login" element={<LogInPage />} />
      <Route path="/" element={<ProtectedLayouts />}>
        <Route index element={<Dashboard />} />
        <Route path="about-us" element={<AboutUsContent />} />
        <Route path="our-team" element={<Team />} />
        <Route path="how-we-work" element={<HowWeWork />} />
        <Route path="our-award" element={<AwardPage />} />
        <Route path="services" element={<Services />} />
        <Route path="process-steps" element={<ServiceProcessStep />} />
        <Route path="result" element={<ResultListPage />} />
        <Route path="portfolio" element={<PortfolioPage />} />
        <Route path="careers" element={<Careers />} />
        <Route path="blogs" element={<BlogPage />} />
        <Route path="profile" element={<ProfilePage />} />
        <Route path="contacts-details" element={<ContactPage />} />
        {/* <Route path="messages" element={<MessagePage />} /> */}
        <Route path="Testimonials" element={<Testimonials />} />
        <Route path="seo" element={<SEO />} />
        <Route path="techweoffer" element={<TechnologyWeOffer />} />{" "}
        <Route path="ourclients" element={<OurClients />} />
      </Route>
    </Routes>
  );
};

export default ApplicationRoute;
