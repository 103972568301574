import React from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import HowWeWorkTable from "./Components/HowWeWorkTable";
import { useDispatch } from "react-redux";
import { openDrawer } from "@Redux/actions/drawer.action";
import HowWeWorkForm from "./Components/HowWeWorkForm";

const HowWeWork = () => {
  const dispatch = useDispatch();

  const addFunc = () => {
    dispatch(openDrawer("Add Working Principle", <HowWeWorkForm />));
  };

  return (
    <div className="containers">
      <h6>
        <span className="heading">How We Work</span>
      </h6>

      <div className="add-btn-justify">
        <Button type="primary" icon={<PlusOutlined />} onClick={addFunc}>
          ADD WORKING ACTION
        </Button>
      </div>
      <HowWeWorkTable />
    </div>
  );
};

export default HowWeWork;
