import React from "react"
import { Spin } from "antd"
import { useSelector } from "react-redux"
const Loading = ({ children }) => {
  const { onload } = useSelector((state) => state.loading)
  return (
    <Spin spinning={onload} tip="Loading...">
      {children}
    </Spin>
  )
}

export default Loading
